import { IconProps } from './Icon';

const ChevronLeft = ({ size = { width: 40, height: 40 }, color }: IconProps) => (
  <span style={{ width: size.width, height: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg role={'presentation'} width={size.width} height={size.height} viewBox="0 0 40 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_63_58)">
        <path d="M25.6834 27.65L18.05 20L25.6834 12.35L23.3334 10L13.3334 20L23.3334 30L25.6834 27.65Z" />
      </g>
      <defs>
        <clipPath id="clip0_63_58">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default ChevronLeft;
