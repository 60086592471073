export const enum ChoicesType {
  normal = 1,
  checkbox = 2,
}
export const enum NavigationType {
  forward = 1,
  back = 2,
  start = 3,
  exit = 4,
  button = 5,
}

export enum ResponseLevel {
  gronn = 1,
  gul = 2,
  rod = 3,
}
export enum ResponseType {
  prosesspavirkning = 1,
  prosessforberedelser = 2,
  pavirkning = 3,
  introending = 4,
}

export enum PageType {
  quizsvar = 1,
  quizstart = 2,
}

export const pageTypeMapping = (pagesEnum: PageType): string => {
  if (pagesEnum === PageType.quizstart) return '/quizsvar';
  else return '/quizstart';
};

export enum SvarsideEnum {
  quizsvar_en = '/quizsvar/en',
  quizsvar_to = '/quizsvar/to',
  quizsvar_tre = '/quizsvar/tre',
  quizsvar_en_en = '/quizsvar/en_en',
  quizsvar_en_to = '/quizsvar/en_to',
  quizsvar_to_to = '/quizsvar/to_to',
  quizsvar_to_tre = '/quizsvar/to_tre',
  quizsvar_en_tre = '/quizsvar/en_tre',
  quizsvar_to_en = '/quizsvar/to_en',
  quizsvar_noytral = '/quizsvar/noytral',
}
