/// <reference types="@helsenorge/framework-utils/types/hn"/>

//Id til Sex og Samfunn verktøy i ulike miljøer:
const environmentSpecificChatCardId: Record<string, string> = {
  'mas-02': 'edc148f5-325d-4320-a2f0-bf8b9445e72c',
  test1: 'e0ed1673-ae78-44ff-a027-48252045d0dd',
  test2: 'd86279e2-64f9-46fd-80b8-3dedf961368b',
  '': 'a68c3e61-a1cc-4a62-aff5-59b674559948',
};

function getEnvironmentSpecificId(): string {
  const environment = window.HN?.Rest?.Environment;
  return environment ? environmentSpecificChatCardId[environment] : environmentSpecificChatCardId[''];
}

function doesIdExist(givenId: string): boolean {
  return Object.values(environmentSpecificChatCardId).includes(givenId);
}

export const getChatCardId = (givenId: string | undefined) => {
  //Gitt at id-en ikke er en av de angitte id-ene antar vi at den er ny. Ny id skal brukes
  if (givenId && !doesIdExist(givenId)) {
    return givenId;
  } else {
    //gitt id er en av id-ene vi har brukt tidligere. Om id-en angitt er til feil miljø så retter vi det.
    return getEnvironmentSpecificId();
  }
};
