import { IconProps } from './Icon';

const ArrowLeft = ({ size = { width: 40, height: 40 }, color }: IconProps) => (
  <span style={{ width: size.width, height: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg role={'presentation'} xmlns="http://www.w3.org/2000/svg" width={size.width} height={size.height} viewBox="-5 0 40 30" fill={color}>
      <path d="m14.11 27.333 2.33-2.37-9.376-9.24 20.282-.166-.027-3.334-20.283.168 9.223-9.393-2.37-2.33L.668 14.11 14.11 27.333Z" />
    </svg>
  </span>
);

export default ArrowLeft;
