import React from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { getIcon } from './iconSelection';
import { TemaValgButton } from '../../components/temavalg-button';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectPage } from '../../redux/selectors';
import { IntroChoiceUrlEnum, setIntroChoiceUrl, setLane } from '../../redux/selvtestSlice';
import { safeNavigate } from '../../utils/navigate';
import { findPathName } from '../svarside/helper';

import styles from './styles.module.scss';

export const TemaValg: React.FunctionComponent = () => {
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const params = findPathName(location.pathname);
  const erQuizSvarside = params !== 'temavalg';

  React.useEffect(() => {
    dispatch(setIntroChoiceUrl(erQuizSvarside ? IntroChoiceUrlEnum.quizinngang : IntroChoiceUrlEnum.temavalg));
  }, []);
  const temaTitle = localState?.title;
  const temaValg = localState?.choices;
  const temaValgLength = temaValg ? temaValg.length : 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setLane(undefined));
  }, []);

  return (
    <Wrapper>
      <WrapperInner>
        <Title>{temaTitle}</Title>
        <div className={classnames(styles['temavalg-container'], { [styles['temavalg-container--small']]: temaValgLength <= 6 })}>
          {temaValg?.map((tema, index) => {
            return (
              <TemaValgButton
                key={index}
                icon={getIcon(tema.icon)}
                onClick={() => {
                  dispatch(setLane('#' + tema.navigateTo));
                  safeNavigate(navigate, tema.navigateTo);
                }}
              >
                {tema.text}
              </TemaValgButton>
            );
          })}
        </div>
      </WrapperInner>
    </Wrapper>
  );
};
export default TemaValg;
