import Dyr from '../../utils/Icons/Quiz/dyr';
import Familie from '../../utils/Icons/Quiz/Familie';
import IkkeVil from '../../utils/Icons/Quiz/IkkeVil';
import Sarbar from '../../utils/Icons/Quiz/Sarbar';
import Sover from '../../utils/Icons/Quiz/Sover';
import AndresGrenser from '../../utils/Icons/Tema/AndresGrenser';
import BilderOgVideo from '../../utils/Icons/Tema/BilderOgVideoe';
import Onanering from '../../utils/Icons/Tema/Onanering';
import Pedofili from '../../utils/Icons/Tema/Pedofili';
import Porno from '../../utils/Icons/Tema/Porno';
import Samtykke from '../../utils/Icons/Tema/Samtykke';
import SeksuellLavalder from '../../utils/Icons/Tema/SeksuellLavalder';
import TankerOgFantasier from '../../utils/Icons/Tema/TankerOgFantasier';

export const getIcon = (tema?: string) => {
  switch (tema) {
    case 'Onanering':
      return <Onanering />;
    case 'Mine_og_andres_grenser':
      return <AndresGrenser />;
    case 'Tanker_og_fantasier':
      return <TankerOgFantasier />;
    case 'Pedofili':
      return <Pedofili />;
    case 'Porno_og_gaming':
      return <Porno />;
    case 'Seksuell_lavalder':
      return <SeksuellLavalder />;
    case 'Samtykke':
      return <Samtykke />;
    case 'Bilder_og_video':
      return <BilderOgVideo />;
    case 'Ikke_vil':
      return <IkkeVil />;
    case 'Dyr_dukke':
      return <Dyr />;
    case 'Familie':
      return <Familie />;
    case 'Sover':
      return <Sover />;
    case 'Sarbar':
      return <Sarbar />;
    default:
      return null;
  }
};
