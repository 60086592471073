import { IconProps } from './Icon';

const ChevronRight = ({ size = { width: 40, height: 40 }, color = 'black' }: IconProps) => (
  <span style={{ width: size.width, height: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg role={'presentation'} width={size.width} height={size.height} viewBox="0 -2 30 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7142 11.2628L21.7438 19.1428L13.7142 27.0228L15.8745 29.1428L26.0642 19.1428L15.8745 9.14282L13.7142 11.2628Z" />
    </svg>
  </span>
);

export default ChevronRight;
