import React from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationType, PageType } from '../../types/enum';

import SafeHTML from '@helsenorge/framework-utils/components/safe-html';

import ExitSection from '../../components/exit-section';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectAnsweredIntro, selectIntroChoiceUrl, selectPage } from '../../redux/selectors';
import { setAnsweredIntro } from '../../redux/selvtestSlice';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const KanIkkeHjelpe: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const introChoiceUrl = useAppSelector(selectIntroChoiceUrl);
  const answeredIntro = useAppSelector(selectAnsweredIntro);

  const pageType = localState?.type;
  const isQuizPage = pageType && pageType === PageType.quizsvar;
  const temaTitle = localState?.title;
  const temaText1 = localState?.ingress1;

  const navigateForward = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.forward);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isQuizPage && answeredIntro) {
      safeNavigate(navigate, '/');
    }
  }, []);

  return (
    <Wrapper
      tilbakemelding
      backgroundVariant={'dark'}
      onClickForward={() => {
        dispatch(setAnsweredIntro(true));
        safeNavigate(navigate, introChoiceUrl ?? navigateForward?.navigateTo);
      }}
      onClickBack={() => {
        navigate(-1);
      }}
    >
      <WrapperInner>
        <div>
          <Title className={styles['title']}>{temaTitle}</Title>
        </div>
        {temaText1 && (
          <div>
            <SafeHTML className={classnames(styles.text, styles['text-with-button'])} html={temaText1} />
          </div>
        )}
        <ExitSection />
      </WrapperInner>
    </Wrapper>
  );
};
export default KanIkkeHjelpe;
