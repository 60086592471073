import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import selvtest from './selvtestSlice';

export const rootReducer = combineReducers({
  selvtest,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
}
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
