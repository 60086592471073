import React, { useRef, useState } from 'react';

import DictionaryTrigger from '@helsenorge/designsystem-react/components/DictionaryTrigger';
import HelpBubble from '@helsenorge/designsystem-react/components/HelpBubble';

import { useOutsideEvent } from '@helsenorge/designsystem-react';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';

import { useAppSelector } from '../../redux/hooks';
import { selectKeywords } from '../../redux/selectors';

import styles from './styles.module.scss';
interface KeywordInfoDisplayProps {
  inputString: string;
  identifier?: string;
}

export const KeywordInfoDisplay: React.FC<KeywordInfoDisplayProps> = ({ inputString, identifier }) => {
  const keywords = useAppSelector(selectKeywords) || [];

  const escapedKeywords = keywords.map(keyword => keyword.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  const regex = new RegExp(`\\b(${escapedKeywords.join('|')})\\b`, 'gi');

  const parts = inputString.split(regex);
  const text = parts.map((part, index) => {
    const key = identifier ? `${identifier}-${index}` : String(index);

    if (index % 2 === 1) {
      const keyword = part;
      const info = keywords.find(kw => kw.keyword === keyword)?.info || 'Mangler informasjon';
      const controllerRef = useRef<HTMLButtonElement>(null);
      const bubbleRef = useRef<HTMLDivElement>(null);
      const [isOpen, setIsOpen] = useState(false);
      useOutsideEvent(bubbleRef, () => setIsOpen(false));
      return (
        <span key={key}>
          <DictionaryTrigger className={styles.keyword} ref={controllerRef} selected={isOpen} onClick={(): void => setIsOpen(!isOpen)}>
            <SafeHTML html={keyword} />
          </DictionaryTrigger>
          <HelpBubble
            ref={bubbleRef}
            className={styles['info-box']}
            role="tooltip"
            onClose={(): void => setIsOpen(false)}
            controllerRef={controllerRef}
            showBubble={isOpen}
          >
            <SafeHTML html={info} />
          </HelpBubble>
        </span>
      );
    } else {
      return (
        <span key={key}>
          <SafeHTML html={part} />
        </span>
      );
    }
  });

  return (
    <div data-testid="keyword-info-display-container" className={styles['keyword-info-display-container']}>
      {text}
    </div>
  );
};
