import { RootState } from './store';

export const selectPage = (state: RootState, url: string) => state.selvtest.pages?.find(p => p.urlPath === url);

export const selectTemaValg = (state: RootState, url: string) => state.selvtest.pages?.find(p => p.urlPath === url);

export const selectSpecificPage = (state: RootState, url: string | null) => state.selvtest.pages?.find(p => p.urlPath === url);
export const selectSessionId = (state: RootState) => state.selvtest.sessionId;
export const selectCurrentPage = (state: RootState) => state.selvtest.currentPage;
export const selectChosenAnswers = (state: RootState) => state.selvtest.chosenAnswers;
export const selectPoppedUrls = (state: RootState) => state.selvtest.poppedUrls;
export const selectIntroChoiceUrl = (state: RootState) => state.selvtest.introChoiceUrl;
export const selectLane = (state: RootState) => state.selvtest.lane;
export const selectLaneData = (state: RootState) => state.selvtest.pages?.find(p => p.urlPath === state.selvtest.lane);
export const selectPageByUrlPath = (state: RootState, urlPath: string | null) => state.selvtest.pages?.find(p => p.urlPath === urlPath);
export const selectAnsweredIntro = (state: RootState) => state.selvtest.answeredIntro;
export const selectExternalQuestionnaire = (state: RootState) =>
  state.selvtest.externalLinks?.find(l => l.linkName === 'externalQuestionnaire')?.linkValues;
export const selectExternalTilgjengelighetserklaering = (state: RootState) =>
  state.selvtest.externalLinks?.find(l => l.linkName === 'externalTilgjengelighetserklaering')?.linkValues;
export const selectKeywords = (state: RootState) => state.selvtest.keywords;
