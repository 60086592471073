import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChoicesType, NavigationType, PageType, ResponseLevel, ResponseType as ChoiceResponseType, pageTypeMapping } from '../types/enum';

import { getInitialSelvtestData } from '../utils/api-utils';

export interface Choice {
  id: number;
  type?: ChoicesType;
  title?: string;
  ingress?: string;
  text?: string;
  icon?: string;
  value?: boolean | null;
  responseLevel?: ResponseLevel;
  responseType?: ChoiceResponseType;
  navigateTo: string;
}
export interface Resource {
  title: string | null;
  text: string | null;
  navigateTo: string;
}
export interface Navigation {
  text: string | null;
  type: NavigationType;
  navigateTo: string | null;
}

export interface Exit {
  exitTitle: string | null;
  exitIngress: string | null;
}

export interface PageContent {
  urlPath: string;
  title: string;
  subtitle: string | null;
  ingress1?: string | null;
  ingress2?: string | null;
  choices?: Choice[] | null;
  extraChoices?: Choice[] | null;
  resources?: Resource[] | null;
  chatcardId?: string;
  exit?: Exit[] | null;
  navigationButtons?: Navigation[] | null;
  type?: PageType | null;
}
export type ExternalLink = {
  linkName: string;
  linkValues: {
    linkText: string;
    webLink: string;
    mobileLink: string;
  };
};
export type Keyword = {
  keyword: string;
  info: string;
};
export enum IntroChoiceUrlEnum {
  temavalg = '/temavalg',
  quizinngang = '/quiztemavalg',
}
export interface SelvtestState {
  sessionId?: string;
  pages: PageContent[] | null;
  currentPage: string | null;
  chosenAnswers: Choice[] | null;
  poppedUrls: string[] | null;
  introChoiceUrl: IntroChoiceUrlEnum | null;
  lane: string | undefined;
  answeredIntro: boolean;
  externalLinks: ExternalLink[] | null;
  keywords: Keyword[] | null;
}
const initialData = getInitialSelvtestData();

export const selvtestInitialState: SelvtestState = Object.assign(
  {
    sessionId: undefined,
    pages: null,
    currentPage: null,
    chosenAnswers: null,
    poppedUrls: null,
    introChoiceUrl: IntroChoiceUrlEnum.temavalg,
    lane: undefined,
    answeredIntro: false,
    externalLinks: null,
    keywords: null,
  },
  initialData
);

const selvtest = createSlice({
  name: 'selvtest',
  initialState: selvtestInitialState,
  reducers: {
    setPage(state: SelvtestState, action: PayloadAction<string | null>): void {
      state.currentPage = action.payload;
    },
    setSessionId(state: SelvtestState, action: PayloadAction<string>): void {
      state.sessionId = action.payload;
    },
    addAnswers(state: SelvtestState, action: PayloadAction<Choice[]>): void {
      state.chosenAnswers = state.chosenAnswers ? [...state.chosenAnswers, ...action.payload] : action.payload;
    },
    setAnswers(state: SelvtestState, action: PayloadAction<Choice[] | null>): void {
      state.chosenAnswers = action.payload;
    },
    removePoppedAnswers(state: SelvtestState): void {
      if (state.chosenAnswers && state.chosenAnswers.length > 0 && state.poppedUrls && state.poppedUrls.length > 0) {
        state.chosenAnswers.splice(state.chosenAnswers.length - state.poppedUrls.length, state.poppedUrls.length);
        state.poppedUrls = null;
        state.currentPage = null;
      }
    },
    addPoppedUrl(state: SelvtestState, action: PayloadAction<string>): void {
      // Om det skal være mulig å poppe forover (Skiller browser forward fra å skrive inn hash manuelt)
      const canPopForward =
        state.chosenAnswers &&
        state.chosenAnswers.length > 0 &&
        state.chosenAnswers.some(ca => {
          const isQuizAnswerHash = action.payload.includes(pageTypeMapping(PageType.quizsvar));
          return isQuizAnswerHash ? ca.navigateTo.includes(pageTypeMapping(PageType.quizsvar)) : ca.navigateTo === action.payload;
        });
      const filteredPageForward = state.pages?.filter(p => p.urlPath === state.currentPage)[0];
      let allChoicesForward: Choice[] = [];
      // Sjekker om urlen vi skal til matcher forrige side sine knappeurl (da har vi beveget oss forover under history.pop)
      let poppedForward = false;
      if (state.currentPage && canPopForward && filteredPageForward) {
        if (filteredPageForward.choices) {
          allChoicesForward = [...filteredPageForward.choices];
        }
        if (filteredPageForward?.extraChoices) {
          allChoicesForward = [...allChoicesForward, ...filteredPageForward.extraChoices];
        }
        poppedForward = allChoicesForward.some(c => {
          const isQuizAnswerHash = action.payload.includes(pageTypeMapping(PageType.quizsvar));
          return isQuizAnswerHash ? c.navigateTo.includes(pageTypeMapping(PageType.quizsvar)) : c.navigateTo === action.payload;
        });
      }

      // Sjekker om urlen vi kom fra matcher denne siden sine knappeurl (da har vi beveget oss bakover under history.pop)
      const filteredPages: PageContent | undefined = state.pages?.filter(p => p.urlPath === action.payload)[0];

      let allChoices: Choice[] = [];
      let poppedBack = false;

      if (state.currentPage && filteredPages) {
        if (filteredPages.choices) {
          allChoices = [...filteredPages.choices];
        }
        if (filteredPages.extraChoices) {
          allChoices = [...allChoices, ...filteredPages.extraChoices];
        }
        poppedBack = allChoices.some(c => {
          const isQuizAnswerHash = state.currentPage?.includes(pageTypeMapping(PageType.quizsvar));
          return isQuizAnswerHash ? c.navigateTo.includes(pageTypeMapping(PageType.quizsvar)) : c.navigateTo === state.currentPage;
        });
      }

      if (poppedForward && state.poppedUrls && state.poppedUrls.length > 0) {
        state.poppedUrls.pop();
      } else if (poppedBack) {
        state.poppedUrls = state.poppedUrls ? [...state.poppedUrls, action.payload] : [action.payload];
      }

      if (!poppedForward && !poppedBack) {
        state.sessionId = selvtestInitialState.sessionId;
        state.currentPage = selvtestInitialState.currentPage;
        state.chosenAnswers = selvtestInitialState.chosenAnswers;
        state.poppedUrls = selvtestInitialState.poppedUrls;
        window.location.hash = '/';
      }
    },
    setPoppedUrls(state: SelvtestState, action: PayloadAction<string[] | null>): void {
      state.poppedUrls = action.payload;
    },
    setIntroChoiceUrl(state: SelvtestState, action: PayloadAction<IntroChoiceUrlEnum | null>): void {
      state.introChoiceUrl = action.payload;
    },
    setLane(state: SelvtestState, action: PayloadAction<string | undefined>): void {
      state.lane = action.payload;
    },
    setAnsweredIntro(state: SelvtestState, action: PayloadAction<boolean>): void {
      state.answeredIntro = action.payload;
    },
  },
});
export const {
  setPage,
  setSessionId,
  addAnswers,
  setAnswers,
  removePoppedAnswers,
  addPoppedUrl,
  setPoppedUrls,
  setIntroChoiceUrl,
  setLane,
  setAnsweredIntro,
} = selvtest.actions;

export default selvtest.reducer;
