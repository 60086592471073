import React from 'react';

import { useLocation, useNavigationType } from 'react-router-dom';

import { PageType, pageTypeMapping } from '../types/enum';

import { useAppDispatch } from '../redux/hooks';
import { addPoppedUrl, setPage } from '../redux/selvtestSlice';

export const useHistoryListen = () => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const isQuizPage = location.pathname.startsWith('/quiz/') || location.pathname.includes(pageTypeMapping(PageType.quizsvar));
    if (navigationType === 'POP' && isQuizPage) {
      // Vi holder på en liste av sider vi har gått tilbake fra - slik at vi kan slette svarene derfra
      dispatch(addPoppedUrl(location.pathname));
      // Settes nåværende side etter addPoppedUrl har brukt forrige nåværende side i sjekken sin
      dispatch(setPage(location.pathname));
    } else if (navigationType === 'PUSH' && isQuizPage) {
      dispatch(setPage(location.pathname));
    }
  }, [location, navigationType]);
};
