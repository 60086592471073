import React from 'react';

import classNames from 'classnames';

import { useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { Resource } from '../../redux/selvtestSlice';
import ArrowUpRight from '../../utils/Icons/ArrowUpRight';
import VideoIcon from '../../utils/Icons/Video';
import { Title } from '../title';

import styles from './styles.module.scss';

interface Props {
  resource: Resource[];
}

export const LesMerList: React.FunctionComponent<Props> = ({ resource }: Props) => {
  const breakpoint = useBreakpoint();
  const iconSize = breakpoint < breakpoints.md ? { width: 25, height: 25 } : { width: 40, height: 40 };

  if (!resource || resource.length === 0) {
    return null;
  }
  const isYoutube = (href: string) => href.includes('youtube.com') || href.includes('youtu.be');
  const isUngNo = (href: string) => href.includes('ung.no');
  return (
    <div data-testid="les-mer-list-testid" className={styles['les-mer-list-wrapper']}>
      <Title htmlMarkup={'h2'} visualHeading={'h3'}>
        {'Vil du lese mer?'}
      </Title>
      <ul className={styles['les-mer-list']}>
        {resource.map((child, index) => {
          return (
            <li key={index}>
              <a
                data-analyticsid="button"
                href={child.navigateTo}
                target={'_blank'}
                rel="noreferrer"
                className={classNames(styles['les-mer-list__list-item'], {
                  [styles['les-mer-list__list-item--secondary']]: index % 2 === 1,
                })}
              >
                <div>
                  <Title className={styles['les-mer-list__list-item__title']} color={'white'} htmlMarkup={'div'} visualHeading={'h2'}>
                    {child.title}
                  </Title>
                  <div className={styles['les-mer-list__list-item__text']}>{child.text}</div>
                </div>
                {isYoutube(child.navigateTo) && (
                  <span className={styles['les-mer-list__list-item__video-icon']}>
                    <VideoIcon size={iconSize} />
                  </span>
                )}
                <div className={styles['les-mer-list__list-item__icon']}>
                  {isYoutube(child.navigateTo) ? 'Se mer' : 'Les mer'}
                  {isUngNo(child.navigateTo) && ' på Ung.no'}
                  <ArrowUpRight size={iconSize} />
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LesMerList;
