import React from 'react';

import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationType } from '../../types/enum';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import { useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import SafeHTML from '@helsenorge/framework-utils/components/safe-html';

import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectExternalTilgjengelighetserklaering, selectPage } from '../../redux/selectors';
import HdirLogo from '../../utils/Icons/HdirLogo';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const OmVerktoyet: React.FunctionComponent = () => {
  const breakpoint = useBreakpoint();
  const mobile = breakpoint < breakpoints.md;
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const tilgjengelighetserklaering = useAppSelector(selectExternalTilgjengelighetserklaering);

  const title = localState?.title;
  const subtitle = localState?.subtitle;
  const ingress1 = localState?.ingress1;
  const ingress2 = localState?.ingress2;

  const navigate = useNavigate();

  const navigateBack = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.back);
  const navigateForward = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.forward);

  return (
    <Wrapper
      topMenu={'hide'}
      showLogo={'all-viewports'}
      backgroundVariant={'dark'}
      onClickForward={() => safeNavigate(navigate, navigateForward?.navigateTo)}
      onClickBack={() => {
        navigateBack?.navigateTo ? safeNavigate(navigate, navigateBack?.navigateTo) : navigate(-1);
      }}
    >
      <WrapperInner>
        <div>
          {title && <Title visualHeading={'h2-alternate'}>{title}</Title>}
          {ingress1 && (
            <div className={styles.text}>
              <SafeHTML html={ingress1} />
              {tilgjengelighetserklaering && (
                <p>
                  <AnchorLink className={styles.text} href={tilgjengelighetserklaering.webLink} target={'_blank'}>
                    {tilgjengelighetserklaering.linkText}
                  </AnchorLink>
                </p>
              )}
            </div>
          )}
          {subtitle && <div className={classnames(styles.text, styles['text--subtitle'])}>{subtitle}</div>}
          {ingress2 && (
            <div className={styles.text}>
              <SafeHTML html={ingress2} />
            </div>
          )}
        </div>
        {!mobile && (
          <div className={styles['logo-wrapper']}>
            <HdirLogo size={{ width: 549, height: 71 }} />
          </div>
        )}
      </WrapperInner>
    </Wrapper>
  );
};
export default OmVerktoyet;
