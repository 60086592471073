import { IconProps } from './Icon';

const HdirLogo = ({ size = { width: 100, height: 100 } }: IconProps) => (
  <svg role={'img'} width={size.width} height={size.height} viewBox="0 0 549 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{'Helsedirektoratet logo'}</title>
    <g clipPath="url(#clip0_2474_4343)">
      <path
        d="M24.1855 9.647L24.3436 9.71813C25.1656 10.0843 26.0992 10.1102 26.9403 9.79033C27.7815 9.47046 28.4619 8.83075 28.8329 8.01091L30.3347 4.84937C30.7 4.02746 30.7246 3.09428 30.4031 2.25426C30.0816 1.41425 29.4402 0.735925 28.6195 0.367915L28.4615 0.296783C28.0551 0.114585 27.6169 0.0142098 27.1717 0.00140266C26.7266 -0.0114045 26.2833 0.0635955 25.8671 0.22213C25.4509 0.380665 25.0701 0.61963 24.7463 0.925365C24.4225 1.2311 24.1621 1.59761 23.98 2.00399L22.4783 5.16552C22.1146 5.98672 22.09 6.91829 22.4097 7.75757C22.7294 8.59684 23.3676 9.27591 24.1855 9.647Z"
        fill="white"
      />
      <path
        d="M36.1676 20.4654L35.8672 20.3073C25.5448 15.0354 6.25158 4.81577 3.98318 3.70923C1.86496 2.64221 1.37492 3.28242 0.600344 4.59446C-0.174231 5.90649 -0.427153 6.6969 1.16942 7.85085C2.21273 8.60172 6.97082 11.534 10.1244 13.8973C12.4956 15.6994 14.8668 18.1337 15.4516 20.6392L23.9008 55.8982C24.4225 57.9927 25.5844 57.9295 26.7383 57.7635C27.8923 57.5976 29.1095 57.4711 28.9988 54.8944L27.8528 27.6894C27.7342 25.1049 29.5442 23.9984 31.8521 25.2077L36.1676 27.3417L39.5978 29.041C40.0477 27.1318 40.6612 25.2649 41.4315 23.4609C40.0879 22.6547 37.8037 21.3032 36.1676 20.4654Z"
        fill="white"
      />
      <path
        d="M73.1502 2.48828C70.1625 2.48846 67.1872 2.87363 64.2979 3.63432C64.2979 3.92676 64.2979 4.22711 64.3374 4.51955C64.456 8.61373 64.8354 14.5495 65.2148 17.5056C65.4993 19.687 65.9103 21.6709 67.5148 23.5362C68.8742 25.1169 70.3285 25.9073 72.6839 26.9902C75.0392 28.073 83.196 31.3214 85.5355 32.262C87.875 33.2026 87.6854 34.0166 87.1558 35.55C86.6262 37.0833 86.1125 37.4232 83.8441 36.7672C81.5757 36.1112 71.4904 32.8153 69.1193 32.2146C67.744 31.8668 66.9773 31.8826 65.9182 33.1709C63.5471 36.2376 63.6656 42.1497 65.3728 47.1923C66.1632 49.4054 67.3567 53.2546 68.1471 55.7048C68.9375 58.1549 67.665 58.4869 66.8746 58.8663C65.2938 59.546 64.5034 59.546 63.5154 57.5622C62.5275 55.5783 60.0773 48.7494 57.3188 43.4222C54.5604 38.095 51.549 35.7476 45.8346 32.3568L44.7676 31.7799L39.6064 29.0293C39.2456 30.531 38.9816 32.0543 38.816 33.5898C38.7132 34.6964 38.6421 35.8187 38.6421 36.9648C38.6437 43.78 40.6655 50.4418 44.4522 56.1082C48.2389 61.7747 53.6203 66.1914 59.9165 68.8002C66.2126 71.409 73.1409 72.0927 79.8255 70.7651C86.5102 69.4374 92.6512 66.1579 97.4725 61.341C102.294 56.5241 105.579 50.3861 106.913 43.7026C108.247 37.0192 107.569 30.0904 104.966 23.7918C102.363 17.4933 97.9513 12.1078 92.2883 8.31593C86.6254 4.52408 79.9654 2.4961 73.1502 2.48828ZM78.6829 17.9719L76.3592 20.343C75.7729 20.9702 74.9617 21.3391 74.1037 21.3687C73.2457 21.3984 72.411 21.0863 71.7828 20.5011L71.6643 20.3905C71.0409 19.802 70.6746 18.9915 70.6451 18.1348C70.6155 17.278 70.9249 16.4442 71.5062 15.8141L73.8299 13.443C74.4184 12.8196 75.2289 12.4533 76.0856 12.4237C76.9424 12.3941 77.7762 12.7036 78.4063 13.2849L78.5248 13.4035C78.8373 13.6909 79.0901 14.0372 79.2687 14.4223C79.4474 14.8075 79.5484 15.2241 79.566 15.6484C79.5836 16.0726 79.5175 16.4962 79.3714 16.8948C79.2253 17.2935 79.0021 17.6595 78.7145 17.9719H78.6829Z"
        fill="white"
      />
      <path
        d="M43.4711 19.4436C42.7139 20.7169 42.0381 22.0368 41.4478 23.3955L44.7595 25.0791L45.8265 25.6244C45.8265 25.6244 51.3592 28.2248 53.1296 28.9282C57.2475 30.5959 59.2393 24.2808 59.2393 19.4436C59.2393 16.6141 59.3183 7.46144 59.3183 6.26796V5.39062C52.6949 8.3002 47.1519 13.2156 43.4711 19.4436Z"
        fill="white"
      />
      <path d="M39.5981 29.0156L39.614 29.0236L39.5981 29.0156Z" fill="#E73289" />
      <path
        d="M164.637 55.7293H157.927V38.5227H140.586V55.7293H133.852V16.9531H140.586V33.0928H157.927V16.9531H164.637V55.7293Z"
        fill="white"
      />
      <path
        d="M184.515 56.2638C180.421 56.2638 177.099 54.9728 174.548 52.3909C171.998 49.809 170.723 46.3682 170.723 42.0685V41.2781C170.675 38.615 171.246 35.9771 172.391 33.5719C173.415 31.4019 175.035 29.568 177.062 28.2842C179.072 27.0255 181.401 26.3699 183.772 26.3952C187.692 26.3952 190.725 27.6467 192.869 30.1496C195.014 32.6524 196.084 36.1934 196.078 40.7723V43.3885H177.228C177.301 45.4963 178.145 47.504 179.599 49.0318C180.29 49.717 181.112 50.2546 182.017 50.612C182.922 50.9693 183.89 51.1389 184.863 51.1105C186.27 51.1362 187.662 50.8228 188.922 50.1968C190.182 49.5709 191.273 48.6508 192.103 47.5143L195.588 50.8418C194.436 52.5661 192.842 53.9499 190.973 54.849C188.958 55.812 186.748 56.2962 184.515 56.2638ZM183.725 31.5802C182.914 31.5513 182.108 31.7039 181.364 32.0266C180.62 32.3493 179.958 32.834 179.425 33.4454C178.229 34.9314 177.5 36.7384 177.331 38.6383H189.661V38.1561C189.659 36.3708 189.048 34.6394 187.93 33.2478C187.392 32.6814 186.737 32.2388 186.011 31.9508C185.285 31.6628 184.505 31.5364 183.725 31.5802Z"
        fill="white"
      />
      <path d="M207.792 55.733H201.318V15.9609H207.792V55.733Z" fill="white" />
      <path
        d="M230.823 47.8986C230.845 47.3725 230.725 46.8502 230.474 46.3869C230.224 45.9237 229.853 45.5366 229.401 45.2667C227.919 44.4785 226.316 43.9442 224.658 43.6859C222.751 43.3197 220.894 42.7281 219.126 41.9233C215.89 40.3426 214.275 38.0794 214.281 35.1339C214.277 33.9197 214.562 32.722 215.112 31.6393C215.662 30.5566 216.46 29.6197 217.442 28.9057C219.734 27.1728 222.555 26.2874 225.425 26.4002C228.871 26.4002 231.656 27.2512 233.779 28.9531C234.79 29.7325 235.603 30.739 236.153 31.8911C236.702 33.0432 236.972 34.3085 236.941 35.5845H230.476C230.489 34.9971 230.371 34.4142 230.13 33.8782C229.889 33.3423 229.532 32.8667 229.085 32.4862C228.061 31.6226 226.747 31.1791 225.409 31.2452C224.177 31.1924 222.96 31.5389 221.94 32.2332C221.514 32.5248 221.167 32.9184 220.933 33.3782C220.698 33.838 220.582 34.3492 220.596 34.8652C220.58 35.3283 220.688 35.7873 220.907 36.1954C221.126 36.6036 221.45 36.9464 221.845 37.1889C223.436 37.9986 225.142 38.5584 226.903 38.8487C228.974 39.2489 230.984 39.9213 232.878 40.8484C234.172 41.4876 235.281 42.4449 236.103 43.6306C236.833 44.8166 237.198 46.1907 237.154 47.5825C237.181 48.8207 236.9 50.0463 236.337 51.1496C235.775 52.2529 234.947 53.1998 233.93 53.9055C231.79 55.5179 228.977 56.3215 225.488 56.3162C223.313 56.355 221.156 55.9208 219.165 55.0437C217.434 54.2845 215.943 53.0687 214.85 51.5265C213.835 50.1226 213.282 48.4375 213.269 46.7052H219.592C219.598 47.3849 219.757 48.0545 220.056 48.6647C220.356 49.2748 220.789 49.8099 221.323 50.2303C222.577 51.103 224.091 51.5213 225.615 51.4158C226.978 51.5064 228.336 51.1663 229.496 50.4437C229.912 50.1645 230.252 49.7852 230.484 49.3405C230.716 48.8959 230.833 48.4001 230.823 47.8986Z"
        fill="white"
      />
      <path
        d="M255.341 56.2638C251.247 56.2638 247.924 54.9728 245.374 52.3909C242.824 49.809 241.549 46.3682 241.549 42.0685V41.2781C241.501 38.615 242.072 35.9771 243.216 33.5719C244.24 31.4019 245.86 29.568 247.888 28.2842C249.897 27.0255 252.226 26.3699 254.598 26.3952C258.518 26.3952 261.551 27.6467 263.695 30.1496C265.84 32.6524 266.909 36.1934 266.904 40.7723V43.3885H248.077C248.151 45.4963 248.994 47.504 250.448 49.0318C251.139 49.7178 251.961 50.2559 252.866 50.6133C253.771 50.9707 254.74 51.1398 255.712 51.1105C257.119 51.1362 258.511 50.8228 259.772 50.1968C261.032 49.5709 262.123 48.6508 262.952 47.5143L266.438 50.8418C265.283 52.5643 263.69 53.9476 261.822 54.849C259.8 55.8155 257.582 56.2998 255.341 56.2638ZM254.55 31.5802C253.74 31.5513 252.934 31.7039 252.19 32.0266C251.446 32.3493 250.783 32.834 250.251 33.4454C249.051 34.9294 248.322 36.7375 248.156 38.6383H260.486V38.1561C260.484 36.3708 259.874 34.6394 258.755 33.2478C258.22 32.6845 257.569 32.2438 256.847 31.9559C256.126 31.668 255.35 31.5399 254.574 31.5802H254.55Z"
        fill="white"
      />
      <path
        d="M270.548 41.1107C270.548 36.674 271.578 33.1094 273.638 30.4169C274.587 29.1238 275.836 28.0811 277.278 27.3788C278.72 26.6765 280.311 26.3357 281.914 26.3859C283.305 26.3323 284.691 26.5881 285.972 27.1348C287.252 27.6815 288.396 28.5055 289.319 29.5474V15.9766H295.793V55.7328H289.928L289.612 52.7452C288.681 53.8912 287.497 54.8054 286.153 55.4158C284.809 56.0261 283.341 56.316 281.866 56.2624C280.274 56.2966 278.697 55.9441 277.271 55.2351C275.845 54.5262 274.612 53.4819 273.678 52.1919C271.591 49.473 270.548 45.7793 270.548 41.1107ZM277.013 41.6719C277.013 44.6042 277.582 46.8884 278.705 48.5324C279.229 49.3184 279.946 49.957 280.787 50.3873C281.628 50.8176 282.566 51.0252 283.51 50.9905C284.72 51.0427 285.919 50.734 286.953 50.1035C287.988 49.473 288.811 48.5492 289.319 47.4496V35.1434C288.81 34.0624 287.992 33.157 286.967 32.542C285.943 31.9271 284.759 31.6303 283.565 31.6894C282.612 31.6503 281.666 31.8583 280.817 32.2932C279.968 32.728 279.246 33.3749 278.72 34.1712C277.566 35.8415 276.989 38.3417 276.989 41.6719H277.013Z"
        fill="white"
      />
      <path d="M309.648 55.7313H303.183V26.9219H309.648V55.7313Z" fill="white" />
      <path
        d="M332.143 32.8357C331.27 32.6921 330.387 32.6208 329.503 32.6223C326.542 32.6223 324.545 33.7578 323.512 36.0289V55.7331H317.039V26.9237H323.219L323.377 30.1484C324.019 28.9928 324.962 28.0332 326.106 27.3719C327.251 26.7107 328.553 26.3727 329.874 26.3941C330.655 26.3675 331.434 26.4937 332.167 26.7656L332.143 32.8357Z"
        fill="white"
      />
      <path
        d="M347.452 56.2679C345.613 56.34 343.779 56.0334 342.063 55.3672C340.347 54.701 338.786 53.6894 337.478 52.3951C334.933 49.8131 333.66 46.3724 333.66 42.0727V41.2823C333.612 38.6191 334.183 35.981 335.328 33.576C336.349 31.4041 337.969 29.5694 339.999 28.2884C342.007 27.0259 344.337 26.37 346.709 26.3994C350.63 26.3994 353.659 27.6508 355.799 30.1537C357.941 32.6513 359.016 36.2001 359.016 40.7765V43.3847H340.22C340.3 45.4911 341.143 47.4965 342.591 49.028C343.279 49.7153 344.1 50.2543 345.004 50.6118C345.908 50.9694 346.876 51.1378 347.847 51.1067C349.256 51.1343 350.65 50.8219 351.912 50.1958C353.173 49.5698 354.265 48.6486 355.095 47.5105L358.581 50.8459C357.422 52.5646 355.829 53.947 353.965 54.8532C351.934 55.8245 349.704 56.3089 347.452 56.2679ZM346.662 31.5843C345.85 31.5549 345.042 31.7071 344.297 32.0298C343.552 32.3525 342.888 32.8376 342.354 33.4496C341.161 34.9362 340.435 36.7433 340.268 38.6424H352.598V38.1603C352.593 36.373 351.983 34.64 350.867 33.2441C350.33 32.6829 349.679 32.2435 348.957 31.9558C348.236 31.6681 347.461 31.5387 346.686 31.5764L346.662 31.5843Z"
        fill="white"
      />
      <path
        d="M373.227 43.3792L370.35 46.3353V55.7329H363.885V15.9688H370.35V38.4314L372.373 35.8943L380.341 26.9234H388.118L377.408 38.9373L389.264 55.7329H381.787L373.227 43.3792Z"
        fill="white"
      />
      <path
        d="M400.289 19.918V26.9208H405.371V31.7184H400.289V47.7948C400.216 48.6371 400.446 49.478 400.937 50.1659C401.258 50.4491 401.635 50.661 402.044 50.7877C402.453 50.9144 402.884 50.953 403.309 50.901C404.07 50.8965 404.828 50.8064 405.569 50.6322V55.6354C404.184 56.0342 402.75 56.2416 401.309 56.2519C396.34 56.2519 393.856 53.5092 393.856 48.024V31.7184H389.113V26.9208H393.856V19.918H400.289Z"
        fill="white"
      />
      <path
        d="M408.13 41.0588C408.09 38.421 408.664 35.8099 409.806 33.4316C410.842 31.2638 412.491 29.4474 414.548 28.2072C416.661 26.9727 419.073 26.3437 421.519 26.3893C423.275 26.3178 425.026 26.6149 426.66 27.2616C428.293 27.9082 429.773 28.89 431.004 30.1436C433.433 32.6413 434.75 35.9609 434.956 40.1025V41.62C435.002 44.249 434.442 46.8533 433.32 49.2314C432.302 51.3857 430.672 53.1921 428.633 54.4242C426.501 55.6774 424.063 56.3149 421.591 56.2658C417.528 56.2658 414.274 54.9116 411.829 52.2032C409.384 49.4949 408.165 45.8881 408.17 41.3829L408.13 41.0588ZM414.604 41.62C414.604 44.5866 415.215 46.9077 416.437 48.5833C417.014 49.3944 417.783 50.049 418.676 50.4878C419.569 50.9267 420.557 51.136 421.551 51.0967C422.547 51.1355 423.537 50.9217 424.428 50.4754C425.319 50.029 426.083 49.3646 426.649 48.5438C427.866 46.8365 428.475 44.3468 428.475 41.0588C428.475 38.1502 427.85 35.8423 426.602 34.1351C426.03 33.3189 425.263 32.6581 424.372 32.2124C423.48 31.7666 422.492 31.55 421.496 31.5822C420.515 31.5532 419.543 31.7676 418.666 32.2062C417.789 32.6448 417.034 33.2939 416.469 34.0956C415.22 35.7712 414.604 38.2846 414.604 41.62Z"
        fill="white"
      />
      <path
        d="M455.316 32.8359C454.444 32.692 453.561 32.6206 452.676 32.6225C449.715 32.6225 447.718 33.758 446.685 36.0291V55.7333H440.212V26.9239H446.393L446.551 30.1486C447.192 28.993 448.135 28.0334 449.28 27.3721C450.424 26.7109 451.726 26.3729 453.048 26.3943C453.829 26.3669 454.608 26.4931 455.34 26.7658L455.316 32.8359Z"
        fill="white"
      />
      <path
        d="M495.925 19.918V26.9208H501.016V31.7184H495.925V47.7948C495.854 48.6381 496.087 49.479 496.581 50.1659C496.902 50.4495 497.279 50.6617 497.688 50.7884C498.097 50.9151 498.528 50.9535 498.953 50.901C499.714 50.8961 500.472 50.8059 501.213 50.6322V55.6354C499.828 56.0342 498.394 56.2416 496.953 56.2519C491.979 56.2519 489.494 53.5092 489.5 48.024V31.7184H484.757V26.9208H489.5V19.918H495.925Z"
        fill="white"
      />
      <path
        d="M518.436 56.2679C514.342 56.2679 511.019 54.977 508.469 52.3951C505.919 49.8131 504.636 46.3724 504.62 42.0727V41.2823C504.572 38.6191 505.143 35.981 506.288 33.576C507.309 31.4041 508.929 29.5694 510.959 28.2884C512.967 27.0259 515.297 26.37 517.669 26.3994C521.59 26.3994 524.622 27.6508 526.767 30.1537C528.901 32.6513 529.976 36.2001 529.976 40.7765V43.3847H511.172C511.246 45.4925 512.09 47.5002 513.543 49.028C514.237 49.7198 515.066 50.2608 515.979 50.6172C516.892 50.9735 517.868 51.1375 518.847 51.0989C520.254 51.1265 521.647 50.814 522.908 50.1879C524.168 49.5618 525.259 48.6406 526.087 47.5026L529.572 50.838C528.415 52.5585 526.823 53.9413 524.957 54.8453C522.923 55.8205 520.691 56.3076 518.436 56.2679ZM517.646 31.5843C516.835 31.5538 516.028 31.7055 515.284 32.0284C514.54 32.3512 513.877 32.8368 513.346 33.4496C512.15 34.9355 511.421 36.7425 511.251 38.6424H523.589V38.1603C523.585 36.373 522.975 34.64 521.858 33.2441C521.321 32.681 520.669 32.2405 519.946 31.9527C519.223 31.6649 518.446 31.5365 517.669 31.5764L517.646 31.5843Z"
        fill="white"
      />
      <path
        d="M543.752 19.918V26.9208H548.881V31.7184H543.791V47.7948C543.716 48.6385 543.949 49.4809 544.447 50.1659C544.762 50.4436 545.131 50.6525 545.531 50.779C545.932 50.9056 546.354 50.9472 546.771 50.901C547.535 50.8965 548.296 50.8064 549.039 50.6322V55.6354C547.654 56.0335 546.221 56.2409 544.779 56.2519C539.81 56.2519 537.323 53.5092 537.318 48.024V31.7184H532.576V26.9208H537.318V19.918H543.752Z"
        fill="white"
      />
      <path
        d="M306.415 23.2245C308.419 23.2245 310.043 21.6002 310.043 19.5966C310.043 17.593 308.419 15.9688 306.415 15.9688C304.412 15.9688 302.788 17.593 302.788 19.5966C302.788 21.6002 304.412 23.2245 306.415 23.2245Z"
        fill="white"
      />
      <path
        d="M475.652 55.7232C475.28 54.8668 475.03 53.9621 474.909 53.0359C473.001 54.9643 470.44 56.1097 467.731 56.2472C465.021 56.3846 462.358 55.5042 460.264 53.7789C459.385 53.0216 458.684 52.0803 458.21 51.0217C457.736 49.9631 457.501 48.8131 457.521 47.6534C457.452 46.283 457.73 44.9174 458.328 43.6825C458.926 42.4476 459.826 41.3832 460.943 40.5874C463.22 38.9434 466.481 38.1214 470.728 38.1214H474.68V36.2324C474.745 34.922 474.297 33.6379 473.431 32.6519C472.926 32.1734 472.326 31.8065 471.669 31.575C471.013 31.3435 470.315 31.2527 469.622 31.3083C468.324 31.25 467.045 31.6408 466.002 32.4148C465.559 32.735 465.199 33.157 464.952 33.6453C464.706 34.1336 464.581 34.6739 464.587 35.2207H458.414C458.429 33.6073 458.986 32.0458 459.995 30.7866C460.995 29.3709 462.361 28.2535 463.947 27.554C465.854 26.75 467.908 26.349 469.977 26.3763C473.35 26.3763 476.04 27.2247 478.047 28.9213C480.055 30.618 481.085 33.0023 481.138 36.0743V49.0682C481.055 51.337 481.426 53.5995 482.228 55.7232H475.621H475.652ZM468.539 51.0679C469.8 51.0693 471.041 50.7482 472.143 50.1352C473.218 49.5668 474.103 48.6957 474.688 47.6297V42.1998H471.202C468.8 42.1998 466.998 42.616 465.796 43.4486C465.216 43.8336 464.744 44.3617 464.427 44.9819C464.11 45.6021 463.958 46.2935 463.986 46.9895C463.962 47.5449 464.059 48.099 464.272 48.6126C464.485 49.1261 464.809 49.5866 465.219 49.9613C466.151 50.7341 467.338 51.129 468.547 51.0679H468.539Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2474_4343">
        <rect width="549" height="71.419" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HdirLogo;
