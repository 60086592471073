import { IconProps } from './Icon';

const ArrowRight = ({ size = { width: 40, height: 40 }, color }: IconProps) => (
  <span style={{ width: size.width, height: size.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg role={'presentation'} width="9" height="18" viewBox="0 0 9 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33333 0.667969L0 9.0013L8.33333 17.3346V0.667969Z" fill="white" />
    </svg>
  </span>
);

export default ArrowRight;
