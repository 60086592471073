import { UtvidetInformasjonVeiledningstjeneste } from '../types/verktoynetcoreentities';

import { get } from '@helsenorge/framework-utils/hn-proxy-service';

import fallback from '../__data__/selvtest_23_14.json';
import { ExternalLink, Keyword, PageContent } from '../redux/selvtestSlice';

declare global {
  interface Window {
    selvtestConfig: CmsContentType;
  }
}
interface CmsContentType {
  pages: PageContent[] | null;
  externalLinks: ExternalLink[];
  keywords: Keyword[];
}

export const getInitialSelvtestData = (): CmsContentType => {
  const cmsData = window?.selvtestConfig;

  return cmsData?.pages !== null ? cmsData : fallback;
};

export const getChatCardData = async (id: string): Promise<UtvidetInformasjonVeiledningstjeneste | null> => {
  return await get<UtvidetInformasjonVeiledningstjeneste>('verktoynetcore', 'api/v1/UtvidetInformasjonVeiledningstjeneste', {
    VerktoyId: id,
  });
};
