import React from 'react';

import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { useBreakpoint } from '@helsenorge/designsystem-react';

import { useAppSelector } from '../../../redux/hooks';
import { selectExternalQuestionnaire } from '../../../redux/selectors';

import styles from './styles.module.scss';

export const ExternalQuestionnaire: React.FC = () => {
  const breakpoint = useBreakpoint();
  const isMobileOrTablet = breakpoint <= breakpoints.md;
  const externalQuestionaire = useAppSelector(selectExternalQuestionnaire);

  const url = isMobileOrTablet ? externalQuestionaire?.mobileLink : externalQuestionaire?.webLink;
  const title = externalQuestionaire?.linkText;
  return (
    <a className={styles.questionnaire} href={url} target="_blank" title={title} rel="noopener noreferrer">
      {title}
    </a>
  );
};
