import React from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { NavigationType, SvarsideEnum } from '../../types/enum';

import { findPathName } from './helper';
import ChatCards from '../../components/chat-cards';
import ExitSection from '../../components/exit-section';
import { FinnUtMer } from '../../components/finn-ut-mer';
import { IconDisplay } from '../../components/icon-display';
import { KeywordInfoDisplay } from '../../components/keyword-info-display';
import LesMerList from '../../components/les-mer-list';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectPage, selectSpecificPage } from '../../redux/selectors';
import { IntroChoiceUrlEnum, setIntroChoiceUrl } from '../../redux/selvtestSlice';
import { RootState } from '../../redux/store';
import { useHistoryListen } from '../../utils/useHistoryListen';

import styles from './styles.module.scss';

export const SvarSide: React.FunctionComponent = () => {
  useHistoryListen();
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));

  const params = findPathName(location.pathname);

  const temaTitle = localState?.title;
  const subtitle = localState?.subtitle;
  const temaText1 = localState?.ingress1;
  const temaText2 = localState?.ingress2;
  const resources = localState?.resources;
  const chatCardIds = localState?.chatcardId;
  const erQuizSvarside = params.split('temabeskrivelse').length === 1;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setIntroChoiceUrl(erQuizSvarside ? IntroChoiceUrlEnum.quizinngang : IntroChoiceUrlEnum.temavalg));
  }, []);
  const navigateBack = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.back);
  const navigateBackTo = navigateBack?.navigateTo;
  const temaState = navigateBackTo ? useAppSelector((state: RootState) => selectSpecificPage(state, navigateBack.navigateTo)) : undefined;
  const temaResources = temaState?.resources;

  const combinedResources = [...(resources ?? []), ...(temaResources ?? [])];

  const navigate = useNavigate();

  const textClasses = classnames(styles['svarside-container__text'], styles['svarside-container__text--undertekst']);
  const merInfo = erQuizSvarside ? <FinnUtMer /> : combinedResources ? <LesMerList resource={combinedResources} /> : null;
  const svarsideGrønnGrønn = SvarsideEnum.quizsvar_en_en === params;
  const svarsideGrønnGul = SvarsideEnum.quizsvar_en_to === params;
  const alternativeView = svarsideGrønnGrønn || svarsideGrønnGul;
  const first = alternativeView ? <ExitSection /> : !!chatCardIds && <ChatCards cardId={chatCardIds} />;
  const second = merInfo;
  const third = alternativeView ? !!chatCardIds && <ChatCards cardId={chatCardIds} /> : <ExitSection />;

  return (
    <Wrapper
      tilbakemelding
      backgroundVariant={'dark'}
      onClickBack={() => {
        navigate(-1);
      }}
    >
      <WrapperInner>
        <div data-analyticsid="svarside">
          <div>{erQuizSvarside ? <Title>{temaTitle}</Title> : <IconDisplay erSvarSide text={temaTitle} />}</div>
          <div className={styles['svarside-container']}>
            {temaText1 && erQuizSvarside && (
              <div className={textClasses}>
                <KeywordInfoDisplay inputString={temaText1} identifier="temaText1" />
              </div>
            )}
            {temaText1 && !erQuizSvarside && (
              <div className={styles['svarside-container__answer']}>
                <div className={classnames(styles['svarside-container__text'], styles['svarside-container__answer--margin'])}>
                  {'Svar: '}
                </div>
                <Title>
                  <KeywordInfoDisplay inputString={temaText1} identifier="temaText1" />
                </Title>
              </div>
            )}

            {temaText2 && (
              <div className={textClasses}>
                <KeywordInfoDisplay inputString={temaText2} identifier="temaText2" />
              </div>
            )}
            {(temaText1 || temaText2) && subtitle && (
              <div className={styles['svarside-container__arrow-wrapper']}>
                <Title htmlMarkup={'div'} visualHeading={'h3'}>
                  {subtitle}
                </Title>
              </div>
            )}
            {first}
            {second}
          </div>
          {third}
        </div>
      </WrapperInner>
    </Wrapper>
  );
};
export default SvarSide;
