import React from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../components/button';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectPage } from '../../redux/selectors';
import { IntroChoiceUrlEnum, setIntroChoiceUrl, setLane } from '../../redux/selvtestSlice';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const QuizInngangside: React.FunctionComponent = () => {
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));

  const title = localState?.title;
  const subTitle = localState?.subtitle;
  const choices = localState?.choices;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setLane(undefined));
    dispatch(setIntroChoiceUrl(IntroChoiceUrlEnum.quizinngang));
  }, []);
  return (
    <Wrapper
      onClickBack={() => {
        navigate(-1);
      }}
    >
      <WrapperInner>
        <span className={styles['title-wrapper']}>
          <Title className={styles['title']} visualHeading={'h1-alternate'}>
            {title}
          </Title>
          <Title className={classnames(styles['title'], styles['title--with-underline'])} visualHeading={'h1-alternate'}>
            {subTitle}
          </Title>
        </span>

        <div className={styles['choices-wrapper']}>
          {choices &&
            choices.map(choice => {
              return (
                <Button color="inverted" key={choice.id} onClick={() => safeNavigate(navigate, choice.navigateTo)} rightArrow>
                  {choice.text}
                </Button>
              );
            })}
        </div>
      </WrapperInner>
    </Wrapper>
  );
};
