import React from 'react';

import classnames from 'classnames';

import styles from '../styles.module.scss';

export interface WrapperInnerProps {
  variant?: 'normal' | 'extra-wide';
  alignLeft?: boolean;
  classNames?: string;
  children?: React.ReactNode;
}

export const WrapperInner: React.FunctionComponent<WrapperInnerProps> = ({ variant = 'normal', alignLeft, classNames, children }) => {
  const innterWrapperClasses = classnames(
    styles.wrapper__inner,
    { [styles['wrapper__inner--align-left']]: alignLeft, [styles['wrapper__inner--extra-wide']]: variant === 'extra-wide' },
    classNames
  );

  return <div className={innterWrapperClasses}>{children}</div>;
};
export default WrapperInner;
