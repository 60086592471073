import { IconProps } from './Icon';

const ArrowUpRight = ({ size = { width: 40, height: 40 } }: IconProps) => (
  <span style={{ width: size.width, height: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg width={size.width} height={size.height} viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_1_28)">
        <path d="M8 24L24 8" stroke="#FFDCB5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 8H24V21" stroke="#FFDCB5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_28">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default ArrowUpRight;
