import React from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ResponseType as ChoiceResponseType } from '../../types/enum';

import { Button } from '../../components/button';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import ContentWrapper from '../../components/wrapper/content';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectIntroChoiceUrl, selectPage } from '../../redux/selectors';
import { setAnsweredIntro } from '../../redux/selvtestSlice';
import { harQuizInngang } from '../../utils/helper';
import { safeNavigate } from '../../utils/navigate';

export const HvemErDu: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const introChoiceUrl = useAppSelector(selectIntroChoiceUrl);
  const quiz = harQuizInngang(introChoiceUrl);
  const path = quiz ? '/quizinngang' : introChoiceUrl;
  const temaTitle = localState?.title;
  const choices = localState?.choices;
  const navigate = useNavigate();

  return (
    <Wrapper
      onClickBack={() => {
        navigate(-1);
      }}
    >
      <WrapperInner>
        <Title>{temaTitle}</Title>

        <ContentWrapper>
          {choices &&
            choices.map((tema, index) => {
              return (
                <Button
                  key={index}
                  color={quiz ? 'inverted' : 'normal'}
                  onClick={() => {
                    if (tema.responseType === ChoiceResponseType.introending) {
                      dispatch(setAnsweredIntro(true));
                      safeNavigate(navigate, path ?? tema.navigateTo);
                    } else {
                      safeNavigate(navigate, tema.navigateTo);
                    }
                  }}
                >
                  {tema.text}
                </Button>
              );
            })}
        </ContentWrapper>
      </WrapperInner>
    </Wrapper>
  );
};
export default HvemErDu;
