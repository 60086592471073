import { IntroChoiceUrlEnum } from '../redux/selvtestSlice';

export const harQuizInngang = (url: string | null): boolean => {
  if (url === IntroChoiceUrlEnum.quizinngang) {
    return true;
  }
  return false;
};
export const noHashUrl = (url?: string): string | undefined => {
  return url?.split('#/')[1];
};
