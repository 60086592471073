import { NavigateFunction } from 'react-router-dom';

export const safeNavigate = (navigate: NavigateFunction, navigateTo?: string | null) => {
  const redirect = navigateTo?.includes('https');
  if (redirect && navigateTo) {
    window.location.href = navigateTo;
  } else {
    navigateTo && navigate(navigateTo);
  }
};
