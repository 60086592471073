import React from 'react';

import classnames from 'classnames';

import { Apningstid, UtvidetInformasjonVeiledningstjeneste } from '../../types/verktoynetcoreentities';

import Loader from '@helsenorge/designsystem-react/components/Loader';

import { getChatCardData } from '../../utils/api-utils';
import { getChatCardId } from '../../utils/chatCardId';
import ChatBubble from '../../utils/Icons/ChatCard/ChatBubble';
import Phone from '../../utils/Icons/ChatCard/Phone';
import { Title } from '../title';

import styles from './styles.module.scss';

const getDayText = (index: number): string => {
  const daysArray = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];
  return daysArray[index - 1];
};

const isWithinTime = (currentTime: string, fromTime?: string | null, toTime?: string | null) => {
  if (!fromTime || !toTime) return false;
  return currentTime >= fromTime && currentTime <= toTime;
};

// Finner den første som har høyere dag index, ellers finn den første lavere
const getNextOpenDay = (currentDayIndex: number, openDays: Apningstid[]) => {
  return (
    openDays.find(od => {
      return od.Dag > currentDayIndex && (typeof od.FraKl !== 'undefined' || od.ApenHeleDagen);
    }) ??
    openDays.find(od => {
      return od.Dag < currentDayIndex && (typeof od.FraKl !== 'undefined' || od.ApenHeleDagen);
    })
  );
};

const getOpenClosedTexts = (openDates: Apningstid[]): { statusText: string; timeText: string } => {
  const currentDate = new Date();
  // Lokaliserer og garanterer 0 før tidspunkt - f.eks. blir 9:50 til 09:50
  const currentTime =
    currentDate.getHours().toLocaleString('nb-NO').padStart(2, '0') +
    ':' +
    currentDate.getMinutes().toLocaleString('nb-NO').padStart(2, '0');
  // Setter startindex til mandag (1), og sluttindex til søndag (7)
  const currentDayIndex = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
  const currentOpenDate = openDates.find(od => od.Dag === currentDayIndex);
  const currentOpenFromTime = currentOpenDate?.FraKl;
  const currentOpenToTime = currentOpenDate?.TilKl;

  const isAfterOpeningHours =
    !currentOpenDate?.ApenHeleDagen && (!currentOpenToTime || typeof currentOpenToTime === 'undefined' || currentTime >= currentOpenToTime);
  const currentlyOpen = !isAfterOpeningHours && isWithinTime(currentTime, currentOpenFromTime, currentOpenToTime);
  const nextOpenDate = isAfterOpeningHours ? getNextOpenDay(currentDayIndex, openDates) : undefined;

  const statusText = currentlyOpen ? 'Åpent' : 'Stengt';
  const timeText = currentlyOpen
    ? `Stenger: ${currentOpenToTime}`
    : `Åpner: ${nextOpenDate ? getDayText(nextOpenDate.Dag) : ''} ${nextOpenDate?.FraKl ?? currentOpenFromTime}`;

  return { statusText: statusText, timeText: timeText };
};

type ChatCardsTexts = {
  openClosed: { status: string; time: string };
  title: { text: string; href: string };
  topics: string[];
  tabs: { buttons: string[]; content: React.ReactNode[] };
  bubbleContact: { href: string; ariaLabel: string };
  phoneContact: { href: string; ariaLabel: string };
};

interface ChatCardProps {
  texts: ChatCardsTexts;
}

const ChatCard: React.FunctionComponent<ChatCardProps> = (props: ChatCardProps) => {
  const { texts } = props;

  const [selectedTab, setSelectedTab] = React.useState(0);

  const openClosedTextClasses = classnames(
    styles['chat-card__header__open-closed-text'],
    styles['chat-card__header__open-closed-text--bold']
  );

  const renderTabButton = (text: string, index: number) => {
    const tabButtonClasses = classnames(styles['chat-card__tab-button'], {
      [styles['chat-card__tab-button--active']]: selectedTab === index,
    });

    return (
      <button key={index} onClick={() => setSelectedTab(index)} className={tabButtonClasses}>
        {text}
      </button>
    );
  };

  const renderTabContent = (children: React.ReactNode, index: number) => {
    const tabContentClasses = classnames(styles['chat-card__tab-content'], {
      [styles['chat-card__tab-content--active']]: selectedTab === index,
    });

    return (
      <div key={index} className={tabContentClasses}>
        {children}
      </div>
    );
  };

  return (
    <div className={styles['chat-card']}>
      <div>
        {texts.openClosed && (
          <p className={styles['chat-card__header__open-closed-text']}>
            <span className={openClosedTextClasses}>{texts.openClosed.status}</span>
            {' - '}
            {texts.openClosed.time}
          </p>
        )}
        {texts.title && (
          <a href={texts.title.href} target={'_blank'} className={styles['chat-card__header__anchor-title']} rel="noreferrer">
            {texts.title.text}
          </a>
        )}
        {texts.topics && (
          <div className={styles['chat-card__header__topic-wrapper']}>
            {texts.topics.map((topic, index) => {
              return (
                <span key={index} className={styles['chat-card__header__topic']}>
                  {topic}
                </span>
              );
            })}
          </div>
        )}
      </div>
      {texts.tabs && (
        <div className={styles['chat-card__content']}>
          <div role={'region'}>
            <div className={styles['chat-card__tab-list']} role={'tablist'}>
              {texts.tabs.buttons.map((button, index) => {
                return renderTabButton(button, index);
              })}
            </div>
            {texts.tabs.content.map((c, index) => {
              return renderTabContent(c, index);
            })}
          </div>
        </div>
      )}
      <div className={styles['chat-card__contact-wrapper']}>
        {texts.bubbleContact && (
          <a
            href={texts.bubbleContact.href}
            target="_blank"
            className={styles['chat-card__contact-button']}
            aria-label={texts.bubbleContact.ariaLabel}
            rel="noreferrer"
          >
            {<ChatBubble />}
          </a>
        )}
        {texts.phoneContact && (
          <a
            href={texts.phoneContact.href}
            target="_blank"
            className={styles['chat-card__contact-button']}
            aria-label={texts.phoneContact.ariaLabel}
            rel="noreferrer"
          >
            {<Phone />}
          </a>
        )}
      </div>
    </div>
  );
};

export interface ChatCardsProps {
  cardId: string;
}

export const ChatCards: React.FunctionComponent<ChatCardsProps> = (props: ChatCardsProps) => {
  const { cardId } = props;

  const [chatCardData, setChatCardData] = React.useState<UtvidetInformasjonVeiledningstjeneste>();
  const [error, setError] = React.useState<string>();
  const chatLoaderId = 'chat-loader';

  React.useEffect(() => {
    fetchCardData();
  }, []);

  const getChatCardText = (): ChatCardsTexts | undefined => {
    if (!chatCardData || typeof chatCardData.Apningstid === 'undefined') return;
    const openTime = chatCardData.Apningstid;
    const openClosedTexts = getOpenClosedTexts(openTime);

    const newChatCardsText = {
      openClosed: { status: openClosedTexts.statusText, time: openClosedTexts.timeText },
      title: {
        text: chatCardData.Navn,
        href: chatCardData.Lenker[0],
      },
      topics: chatCardData.Sokeord.map(so => so.toUpperCase()) /* ['SEX', 'KJØNNSSYKDOMMER', 'KJØNNSIDENTITET'], */,
      tabs: {
        buttons: ['Info', 'Åpningstider', 'Bemanning'],
        content: [
          <p key={0} className={styles['chat-card__content-text']}>
            {chatCardData.Ingress ?? chatCardData.Beskrivelse}
          </p>,
          <dl key={1} className={styles['chat-card__dl']}>
            {renderOpenDays(chatCardData)}
          </dl>,
          <p key={2} className={styles['chat-card__content-text']}>
            {chatCardData.BemanningTypeNavn
              ? `Det er ${chatCardData.BemanningTypeNavn} som bemanner denne.`
              : 'Det er ikke informasjon om bemanningen.'}
          </p>,
        ],
      },
      bubbleContact: {
        href: chatCardData.Lenker[0],
        ariaLabel: `Åpne chat for ${chatCardData.Navn}`,
      },
      phoneContact: {
        href: `tel:${chatCardData.Telefon}`,
        ariaLabel: `Ring ${chatCardData.Navn} på nummer ${chatCardData.Telefon}}`,
      },
    };

    // setChatCardText(newChatCardsText);
    return newChatCardsText;
  };

  const fetchCardData = async () => {
    try {
      const chatCardJson = await getChatCardData(getChatCardId(cardId));
      const chatCardJsonString = JSON.stringify(chatCardJson);
      const newChatCardData: UtvidetInformasjonVeiledningstjeneste = JSON.parse(chatCardJsonString)[
        'Veiledningstjeneste'
      ] as UtvidetInformasjonVeiledningstjeneste;

      newChatCardData.Apningstid = newChatCardData.Apningstid.map((at: Apningstid) => {
        at.FraKl = at.FraKl ? at.FraKl.slice(0, -3) : '';
        at.TilKl = at.TilKl ? at.TilKl.slice(0, -3) : '';
        return at;
      });

      setChatCardData(newChatCardData);
    } catch {
      setError('Kunne ikke hente chattetjenester, prøv igjen.');
    }
  };

  const renderOpenDays = (cardData: UtvidetInformasjonVeiledningstjeneste) => {
    const dddtArray = [];

    for (let i = 1; i < 7; i++) {
      const ot = cardData.Apningstid.find(at => at.Dag === i);
      const dt = <dt key={'dt' + i}>{getDayText(i)}</dt>;
      const dd = <dd key={'dd' + i}>{ot?.FraKl ? ot.FraKl + ' - ' + ot.TilKl : 'Stengt'}</dd>;
      dddtArray.push(dt, dd);
    }

    return dddtArray;
  };

  const chatCardText = getChatCardText();

  return (
    <div>
      <Title htmlMarkup={'h2'} visualHeading={'h3'}>
        {'Du kan snakke med:'}
      </Title>
      <div className={styles['chatcards-text']}>
        <ul>
          <li>{'helsesykepleier på skolen'}</li>
          <li>{'helsestasjon for ungdom'}</li>
          <li>{'fastlegen'}</li>
          <li>{'en voksen du stoler på'}</li>
        </ul>
      </div>
      {chatCardText && (
        <div className={styles['chatcards-wrapper']}>
          <Title htmlMarkup={'h3'} visualHeading={'h4'}>
            {'Du kan også ta kontakt med en av tjenestene under:'}
          </Title>
          <ChatCard texts={chatCardText} />
        </div>
      )}
      {!chatCardText && !error && (
        <>
          <span id={chatLoaderId} className={styles['chatcards-text']}>
            {'Laster inn chattetjenester.'}
          </span>
          <Loader color={'white'} ariaLabelledById={chatLoaderId} />
        </>
      )}
      {error && <span className={styles['chatcards-text']}>{error}</span>}
    </div>
  );
};

export default ChatCards;
