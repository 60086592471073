import { IconProps } from '../Icon';

const ChatBubble = ({ size = { width: 53, height: 53 } }: IconProps) => (
  <span style={{ width: size.width, height: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg width={size.width} height={size.height} viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="53" height="53" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2335_3443" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_2335_3443"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEQklEQVR4nO2dXYgWVRjHf6alVhiBippGQpElKihJIGph1IUtgSFkF4U3UTfljbiuCIpZL+iNH6h1sZAgRXd9IJF2IXghKkpJoqLialAuun6upkZPHDhQybu8+86cOfOcmecH/5tlZ/d9/v+Zd86Z8zFgGIZhGIZhGIahhyHAVOAtYBXQDewDTgA9QB9wz6vP/+yE/51uf4w79nn/t4xBMANYCXzvTZVAugx8B3QC0y2J/zMdWA+cCmh4K50EPgam1TWMB4AOYE9E0wfSYeAdYBg1wBX5gf++FmU6B7xf5SBeAY4pMFpayN3IF1fpxj0Z+EmBsdKm9gJPkTDuDHoPuK7ATMmofuCjFK+Gx3xTUiqib4FRJMLTwK8KTJPAcs3kKSjnpcAdKFGmPl+jSuYDNxWYJBHuC65Fp4rXgFsKzJFI+hN4HSW8CNxWYIpEljvhZpdt/gTgNwVmSEn6HZhUlvmPAEcUmCAKniWNLCOA7QqKFyXaHNv8BcDfCgoXJXJevBrL/FFKn2ZKyToLPBojgE8UFCtKtbZo88fXpLMlGXUDGFdkADsUFCnKtbUo80f7HmDZBYpy3QHGFhFAp4LiJBEtL2IQ/ayCwiQRnfaeBeNlBUVJYpoXMoCNCgqSxNQIGUAVR7ikYP0SyvwnFRQjiWpiiAAWKShEEtUbIQJYraAQSVRdIQL4SkEhkqh2hQjgcOAP1QpRfnw7OkQAXKfCAiDzXKLc9FoAZD0BL4YIIPQDuFaI8uPbkfMuNxYAmQNw03Vy4y4juwLI5MEfIQJw66ksADIv9sjNQQuArCeg8y431hEjcwDOu9ysCvwVVCetDhFAh4JCJFG5B5m5maSgEElUzxIIG5AhUxM02OK+hoKzSRLTFwRkjoKCJDG9HTKAocB5BUVJIvoLGENgVigoTBLRbgrgcZuYS9TmZzNsci6Dav08WFQAE/062bIvcVGsTymYNQqKFKXqL2pm9H9xKwJtiRJNA3BTOKOw0Bbp0WzhdqGrY+5nk4JLXhRpHZEZDhxVULgoUI9fuB6dZwqYtiJ1nQOaldk1Xzn5DQpY4BenSc3UG/vG22rkrE57BgnwJsqYC1xRYIxE0Oco3gu66h21Q8AIlG9b+bUCo6QAXfKb0arHjYcuq9jN+a5fspsUrq/wgwLzJMCeQO+SMB2J3xu6qAAPAx8mGMQWKoYbMVoK/KzA3Fb6LMXNu9t9b8wGpdtfdofedEMzQ/0msO4lPj8qGPrcVifzm/EQMNO/isoNg34JHACOAxeAq96ofr+p9pmAUygbVf/aKRLJOaHK9V2MHOTZcK/U5/pVQTLINQBmlf3Bq4JkWMfltuM0AtGO+Tt959AIyGCMd292WhLynxr/0sr8I/4BoVEQAxl/z7fvXf/CKBAZYAO9F8z1OMh9AygNP4nMiBzAfuA5cz0+1/z7H2v9IK1Mnij7AxiGYZAG/wDCT5jACFWASQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  </span>
);

export default ChatBubble;
