import React from 'react';

import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { NavigationType } from '../../types/enum';

import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { useBreakpoint } from '@helsenorge/designsystem-react';

import { findPathName, findPathNameNoHash } from '../../containers/svarside/helper';
import { getIcon } from '../../containers/temavalg/iconSelection';
import { useAppSelector } from '../../redux/hooks';
import { selectIntroChoiceUrl, selectLane, selectSpecificPage } from '../../redux/selectors';
import { IntroChoiceUrlEnum } from '../../redux/selvtestSlice';
import { RootState } from '../../redux/store';
import { IconProps } from '../../utils/Icons/Icon';

import styles from './styles.module.scss';

interface Props {
  erSvarSide?: boolean;
  text?: string;
}
export const IconDisplay = (props: Props) => {
  const introChoiceUrl = useAppSelector(selectIntroChoiceUrl);
  const quiz = introChoiceUrl === IntroChoiceUrlEnum.quizinngang;
  const temaer = useAppSelector((state: RootState) => selectSpecificPage(state, introChoiceUrl));
  const lane = useAppSelector(selectLane);
  const location = useLocation();
  const params = lane ? findPathNameNoHash(lane) : findPathName(location.pathname);
  //Fix for refresh på side
  const paramEtterRefresh = useAppSelector(state => selectSpecificPage(state, location.pathname))?.navigationButtons?.find(
    nav => nav.type === NavigationType.back
  )?.navigateTo;
  const tema = temaer?.choices?.find(c => c.navigateTo === params) ?? temaer?.choices?.find(c => c.navigateTo === paramEtterRefresh);

  const breakpoint = useBreakpoint();
  const mobile = breakpoint < breakpoints.md;
  const temavalgClasses = classnames(styles['icon-display'], { [styles['icon-display--quiz']]: quiz });
  const HtmlMarkup = quiz || props.erSvarSide ? 'div' : 'h2';

  if (tema || props.text) {
    const renderIcon = (icon: string) => {
      let iconSize = { width: 90, height: 90 };
      if (mobile) {
        iconSize = { width: 45, height: 45 };
      }

      return icon ? React.cloneElement(getIcon(icon) as React.ReactElement<IconProps>, { size: iconSize }) : null;
    };
    const svarsidetekst = () => {
      return (
        <div className={classnames({ [styles['icon-display--svarside']]: props.erSvarSide })}>
          <div>{'Spørsmål:'}</div>
          <div>{props.text}</div>
        </div>
      );
    };

    return (
      <div className={temavalgClasses}>
        {tema?.icon && <div className={styles['icon-display__icon']}>{renderIcon(tema.icon)}</div>}
        {(tema?.text || props.text) && (
          <HtmlMarkup className={classnames(styles['icon-display__text'], { [styles['icon-display--svarside']]: props.erSvarSide })}>
            {props.text ? svarsidetekst() : tema?.text}
          </HtmlMarkup>
        )}
      </div>
    );
  }
  return <></>;
};
