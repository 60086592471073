import React from 'react';

import classNames from 'classnames';

import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { useBreakpoint } from '@helsenorge/designsystem-react';

import svarsideStyles from '../../containers/svarside/styles.module.scss';

import styles from './styles.module.scss';

export const FinnUtMer: React.FC = () => {
  const containerClass = classNames(svarsideStyles['svarside-container'], styles['finn-ut-mer']);
  const breakpoint = useBreakpoint();
  const isMobileOrTablet = breakpoint < breakpoints.md;
  return (
    <div className={containerClass}>
      {isMobileOrTablet && <hr className={styles.hr} />}
      <a
        data-analyticsid="button"
        href={'https://www.ung.no/oss'}
        target={'_blank'}
        rel="noreferrer"
        className={styles['finn-ut-mer__button']}
      >
        <div className={styles['finn-ut-mer__bubble']}>
          <div className={styles['finn-ut-mer__bubble--logo']} />
          <div className={styles['finn-ut-mer__bubble--text-section']}>
            <div className={styles['finn-ut-mer__bubble--text']}>{'ung.no'}</div>
            <h3 className={styles['finn-ut-mer__bubble--header']}>{'Spørsmål og svar-tjenesten'}</h3>
            <div className={styles['finn-ut-mer__bubble--text']}>
              {
                'På ung.no/oss kan du lese hva andre har spurt om eller lurt på. Du kan også selv sende inn spørsmål og få svar fra fagpersoner.'
              }
            </div>
          </div>
          <div className={styles['finn-ut-mer__bubble--vector']} />
        </div>
      </a>
      {isMobileOrTablet && <hr className={styles.hr} />}
      <a
        data-analyticsid="button"
        href={'https://www.ung.no/Sex'}
        target={'_blank'}
        rel="noreferrer"
        className={styles['finn-ut-mer__button']}
      >
        <div className={styles['finn-ut-mer__bubble']}>
          <div className={styles['finn-ut-mer__bubble--logo']} />
          <div className={styles['finn-ut-mer__bubble--text-section']}>
            <div className={styles['finn-ut-mer__bubble--text']}>{'ung.no'}</div>
            <h3 className={styles['finn-ut-mer__bubble--header']}>{'Les mer om tema “Sex” på ung.no'}</h3>
            <div className={styles['finn-ut-mer__bubble--text']}>
              {'På ung.no/Sex kan du lese artikler, se videoer og lese spørsmål andre har stilt.'}
            </div>
          </div>
          <div className={styles['finn-ut-mer__bubble--vector']} />
        </div>
      </a>
    </div>
  );
};
