import React from 'react';

import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../components/button';
import { IconDisplay } from '../../components/icon-display';
import { Wrapper } from '../../components/wrapper';
import ContentWrapper from '../../components/wrapper/content';
import wrapperStyles from '../../components/wrapper/content/styles.module.scss';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectPage } from '../../redux/selectors';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const Tema: React.FunctionComponent = () => {
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));

  const temaValg = localState?.choices;
  const navigate = useNavigate();
  const ulClasses = classnames(wrapperStyles['content-wrapper__grid'], styles.liste);
  const liste =
    temaValg &&
    temaValg.map((tema, index) => {
      return (
        <li className={wrapperStyles['content-wrapper__grid']} key={index}>
          <Button
            onClick={() => {
              safeNavigate(navigate, tema.navigateTo);
            }}
          >
            {tema.text}
          </Button>
        </li>
      );
    });
  return (
    <Wrapper
      onClickBack={() => {
        navigate(-1);
      }}
    >
      <WrapperInner>
        <IconDisplay />

        <ContentWrapper gridClass={false}>
          <ul className={ulClasses}>{liste}</ul>
        </ContentWrapper>
      </WrapperInner>
    </Wrapper>
  );
};
export default Tema;
