import React, { useRef } from 'react';

import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { NavigationType } from '../../types/enum';

import { useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { pushToDataLayer, CustomData } from '@helsenorge/framework-utils/adobe-analytics';

import { ExternalQuestionnaire } from './external-questionnaire';
import { findPathName } from '../../containers/svarside/helper';
import { useAppSelector } from '../../redux/hooks';
import { selectIntroChoiceUrl, selectLane, selectPage, selectPageByUrlPath } from '../../redux/selectors';
import { Navigation, setPage } from '../../redux/selvtestSlice';
import { harQuizInngang } from '../../utils/helper';
import SelvtestLogo from '../../utils/Icons/SelvtestLogo';
import { safeNavigate } from '../../utils/navigate';
import { Button } from '../button';

import styles from './styles.module.scss';

export type BackgroundVariant = 'gradient' | 'dark';
export type ShowLogo = 'never' | 'all-viewports' | 'only-mobile';
export type TopMenu = 'hide' | 'show' | 'show-extra-spacing';

interface WrapperProps {
  absoluteNavigation?: boolean;
  backgroundVariant?: BackgroundVariant;
  topRowClassNames?: string;
  showLogo?: ShowLogo;
  noTopPadding?: boolean;
  topMenu?: TopMenu;
  onClickBack?: () => void;
  onClickForward?: () => void;
  wrapperClassNames?: string;
  tilbakemelding?: boolean;
}

export const Wrapper: React.FC<WrapperProps> = ({
  backgroundVariant,
  topRowClassNames,
  showLogo = 'never',
  noTopPadding,
  topMenu = 'show',
  onClickBack,
  onClickForward,
  children,
  wrapperClassNames,
  tilbakemelding,
}) => {
  const [firstFrame, setFirstFrame] = React.useState(true);
  const breakpoint = useBreakpoint();
  const mobile = breakpoint < breakpoints.md;
  const noLogo = showLogo === 'never' || (showLogo === 'only-mobile' && !mobile);
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const introChoiceUrl = useAppSelector(selectIntroChoiceUrl);
  const pickedLane = !!useAppSelector(selectLane);

  const temaUrl = useAppSelector(state => selectPageByUrlPath(state, introChoiceUrl))?.urlPath;
  const params = findPathName(location.pathname);
  const erQuizSvarside = params.split('quizsvar/').length > 1;
  const erTemaSvarside = params.split('temabeskrivelse/').length > 1;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDark = backgroundVariant === 'dark';

  const showTopMenu = topMenu === 'show' || topMenu === 'show-extra-spacing';
  const ref = useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    ref.current?.focus();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    dispatch(setPage(location.pathname));
    setFirstFrame(false);

    const payload: CustomData = {
      selvtestside: { name: localState?.urlPath ?? location.pathname },
    };

    pushToDataLayer(payload);
  }, []);
  const backClicked = (navigateBack: Navigation) => {
    if (onClickBack) {
      onClickBack();
    } else {
      safeNavigate(navigate, navigateBack.navigateTo);
    }
  };

  const navigateBack = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.back);
  const navigateForward = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.forward);
  const wrapperClasses = classnames(
    styles.wrapper,
    {
      [styles['wrapper--no-top-row']]: !showTopMenu && noLogo,
      [styles['wrapper--no-top-padding']]: noTopPadding,
      [styles['wrapper--dark']]: isDark,
    },
    wrapperClassNames
  );
  const wrapperTopRowClasses = classnames(
    styles['wrapper__top-row'],
    {
      [styles['wrapper__top-row--extra-spacing']]: topMenu === 'show-extra-spacing',
    },
    topRowClassNames
  );
  const showTemaUrlNav = pickedLane || erQuizSvarside || erTemaSvarside;

  const navigationButtons = (
    <>
      <span className={styles['navigation-wrapper']}>
        {showTopMenu && (
          <button
            className={styles['navigation-button']}
            data-analyticsid="button"
            type="button"
            onClick={() => safeNavigate(navigate, '/')}
          >
            {'Startsiden'}
          </button>
        )}
        {showTemaUrlNav && introChoiceUrl && temaUrl && (
          <button
            className={styles['navigation-button']}
            data-analyticsid="button"
            type="button"
            onClick={() => safeNavigate(navigate, temaUrl)}
          >
            {harQuizInngang(introChoiceUrl) ? 'Situasjonene' : 'Temaene'}
          </button>
        )}
        {!erQuizSvarside && navigateBack && navigateBack.text && (
          <button className={styles['navigation-button']} data-analyticsid="button" type="button" onClick={() => backClicked(navigateBack)}>
            {navigateBack.text}
          </button>
        )}
      </span>
    </>
  );

  if (firstFrame) return <div className={wrapperClasses} />;
  return (
    <div className={wrapperClasses}>
      <span ref={ref} tabIndex={-1} />
      {tilbakemelding && <ExternalQuestionnaire />}
      {(showTopMenu || navigateBack || !noLogo) && (
        <>
          {navigationButtons}
          {!noLogo && (
            <div className={wrapperTopRowClasses}>
              <div className={styles['logo-tilemap']} />
              <div className={styles['logo-gradient']}>
                <SelvtestLogo size={mobile ? { width: 85, height: 78 } : { width: 251, height: 230 }} />
              </div>
            </div>
          )}
        </>
      )}

      {children}
      {navigateForward && navigateForward.text && (
        <Button className={styles['wrapper__button-row__button-forward--position']} color={'inverted'} onClick={onClickForward} rightArrow>
          {navigateForward.text}
        </Button>
      )}
    </div>
  );
};
