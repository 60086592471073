import React from 'react';

import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ChoicesType, NavigationType, PageType, ResponseLevel, ResponseType, SvarsideEnum } from '../../types/enum';

import { Button } from '../../components/button';
import { IconDisplay } from '../../components/icon-display';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import ContentWrapper from '../../components/wrapper/content';
import { WrapperInner } from '../../components/wrapper/inner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectChosenAnswers, selectPoppedUrls, selectSpecificPage } from '../../redux/selectors';
import {
  addAnswers,
  Choice,
  IntroChoiceUrlEnum,
  removePoppedAnswers,
  setAnswers,
  setIntroChoiceUrl,
  setLane,
  setPoppedUrls,
} from '../../redux/selvtestSlice';
import { RootState } from '../../redux/store';
import { safeNavigate } from '../../utils/navigate';
import { useHistoryListen } from '../../utils/useHistoryListen';

import styles from './styles.module.scss';

// Denne bruker ThunkDispatch for å passe på at det ventes med å kjøre andre funksjoner før etter dette er gjort
export const removePoppedAnswersHandler = () => {
  return async (dispatch: ThunkDispatch<RootState, void, Action>): Promise<void> => {
    dispatch(removePoppedAnswers());
  };
};

export const Quiz: React.FunctionComponent = () => {
  const navigate = useNavigate();
  useHistoryListen();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const localState = useAppSelector((state: RootState) => selectSpecificPage(state, location.pathname));
  const chosenAnswers = useAppSelector(selectChosenAnswers);
  const poppedUrls = useAppSelector(selectPoppedUrls);
  const title = localState?.title;
  const choices = localState?.choices;
  const extraChoices = localState?.extraChoices;

  React.useEffect(() => {
    dispatch(setIntroChoiceUrl(IntroChoiceUrlEnum.quizinngang));
    const isEntryQuizPage = localState?.type === PageType.quizstart;
    const shouldResetAnswers = chosenAnswers && (!poppedUrls || chosenAnswers.length > poppedUrls.length);
    window.addEventListener('beforeunload', onUnload);
    dispatch(setLane(localState?.urlPath));

    if (isEntryQuizPage && shouldResetAnswers) {
      dispatch(setAnswers(null));
      dispatch(setPoppedUrls(null));
    }

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  const onUnload = (event: BeforeUnloadEvent): void | string => {
    const confirmationMessage = 'advarsel';
    event.returnValue = confirmationMessage;
    return event.returnValue;
  };

  const handleButtonAnswer = (c: Choice) => {
    dispatch(removePoppedAnswersHandler());
    dispatch(addAnswers([c]));
    c.navigateTo.includes('quizsvar') ? decideAnswerPage(c) : navigate(c.navigateTo);
  };

  const decideAnswerPage = (lastChoice: Choice) => {
    const allAnswers = chosenAnswers ? [...chosenAnswers, lastChoice] : [lastChoice];
    let pavirkningLevel = -1;
    let prosessPavirkningLevel = -1;
    let prosessForberedelserLevel = -1;

    allAnswers.forEach(a => {
      if (!a.responseLevel) {
        return;
      }
      if (a.responseType === ResponseType.pavirkning && a.responseLevel > pavirkningLevel) {
        pavirkningLevel = a.responseLevel;
      } else if (a.responseType === ResponseType.prosesspavirkning && a.responseLevel > prosessPavirkningLevel) {
        prosessPavirkningLevel = a.responseLevel; //bekymret
      } else if (a.responseType === ResponseType.prosessforberedelser && a.responseLevel > prosessForberedelserLevel) {
        prosessForberedelserLevel = a.responseLevel; //grad
      }
    });

    if (pavirkningLevel === ResponseLevel.gronn) {
      navigate(SvarsideEnum.quizsvar_en);
    } else if (pavirkningLevel === ResponseLevel.gul) {
      navigate(SvarsideEnum.quizsvar_to);
    } else if (pavirkningLevel === ResponseLevel.rod) {
      navigate(SvarsideEnum.quizsvar_tre);
    } else if (prosessPavirkningLevel === ResponseLevel.gronn && prosessForberedelserLevel === ResponseLevel.gronn) {
      navigate(SvarsideEnum.quizsvar_en_en);
    } else if (prosessPavirkningLevel === ResponseLevel.gronn && prosessForberedelserLevel === ResponseLevel.gul) {
      navigate(SvarsideEnum.quizsvar_en_to);
    } else if (prosessPavirkningLevel >= ResponseLevel.gul && prosessForberedelserLevel === ResponseLevel.gul) {
      navigate(SvarsideEnum.quizsvar_to_to);
    } else if (prosessPavirkningLevel >= ResponseLevel.gul && prosessForberedelserLevel === ResponseLevel.rod) {
      navigate(SvarsideEnum.quizsvar_to_tre);
    } else if (prosessPavirkningLevel === ResponseLevel.gronn && prosessForberedelserLevel === ResponseLevel.rod) {
      navigate(SvarsideEnum.quizsvar_en_tre);
    } else if (prosessPavirkningLevel >= ResponseLevel.gul && prosessForberedelserLevel === ResponseLevel.gronn) {
      navigate(SvarsideEnum.quizsvar_to_en);
    } else {
      navigate(SvarsideEnum.quizsvar_noytral);
    }
  };

  const choicesRender =
    choices &&
    choices.map(c => {
      if (c.type === ChoicesType.normal) {
        return (
          <Button color="inverted" key={localState.urlPath + c.text} onClick={() => handleButtonAnswer(c)}>
            {c.text}
          </Button>
        );
      }
    });
  const extraChoicesRender =
    extraChoices &&
    extraChoices.map(c => {
      if (c.type === ChoicesType.normal) {
        return (
          <Button color="inverted" key={localState.urlPath + c.text} onClick={() => handleButtonAnswer(c)}>
            {c.text}
          </Button>
        );
      }
    });

  const choicesLength = choicesRender?.length ?? 0;
  const extraChoicesLength = extraChoicesRender?.length ?? 0;
  const fewChoices = choicesLength + extraChoicesLength <= 2;

  const navigateForward = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.forward);

  const renderContent = (contentToRender: React.ReactNode, renderTitle: boolean, contentWrapperClasses?: string) => {
    return (
      <WrapperInner classNames={classnames({ [styles['wrapper-inner--no-margin']]: renderTitle })}>
        {renderTitle && <IconDisplay />}
        {renderTitle && title && <Title subHeading={localState?.subtitle}>{title}</Title>}
        {
          <div className={classnames(styles.content, styles['content--centered'])}>
            {contentToRender && <ContentWrapper classNames={contentWrapperClasses}>{contentToRender}</ContentWrapper>}
          </div>
        }
      </WrapperInner>
    );
  };

  return (
    <Wrapper
      topRowClassNames={classnames({ [styles['wrapper-top-row--few-options']]: fewChoices })}
      onClickForward={() => safeNavigate(navigate, navigateForward?.navigateTo)}
      onClickBack={() => {
        navigate(-1);
      }}
    >
      {choicesRender && renderContent(choicesRender, true)}
      {extraChoicesRender && renderContent(extraChoicesRender, false, styles['content-wrapper--extra-content'])}
    </Wrapper>
  );
};
