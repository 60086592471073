import React from 'react';

import classnames from 'classnames';

import { useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import ArrowLeft from '../../utils/Icons/ArrowLeft';
import ArrowRight from '../../utils/Icons/ArrowRight';
import ChevronLeft from '../../utils/Icons/ChevronLeft';
import ChevronRight from '../../utils/Icons/ChevronRight';
import { IconProps } from '../../utils/Icons/Icon';
import TopMenuArrow from '../../utils/Icons/TopMenuArrow';
import { Title } from '../title';

import styles from './styles.module.scss';

type ButtonVariant = 'main' | 'main-large' | 'secondary' | 'secondary-top-menu' | 'link';
type ButtonColor = 'normal' | 'inverted';

interface ButtonProps {
  /** Button variant - changes the color appearance */
  variant?: ButtonVariant;
  /** Button color */
  color?: ButtonColor;
  /** Show arrow icon to the right of the label */
  rightArrow?: boolean;
  /** Show arrow icon to the left of the label - only supported for variant secondary button */
  leftArrow?: boolean;
  /** Makes the button scale to full width of its parent element. */
  fluid?: boolean;
  /** Adds custom classes to the element. */
  className?: string;

  /** Adds custom form-id to the element. */
  form?: string;

  /** Function that triggers on click. */
  onClick?: () => void;

  /** Button Title */
  title?: string;

  // Allows buttom to change type
  type?: 'button' | 'submit' | 'reset';

  /** Sets the content of the button. */
  children: React.ReactNode;
}

const getRightIcon = (variant: ButtonVariant, iconSpecs: IconProps) => {
  if (variant === 'main') return <ArrowRight color={iconSpecs.color} size={{ width: iconSpecs.size?.width ?? 0, height: 0 }} />;
  else if (variant === 'main-large')
    return (
      <span className={styles['button__icon']}>
        <ArrowRight color={iconSpecs.color} size={{ width: iconSpecs.size?.width ?? 0, height: 0 }} />
      </span>
    );
  else if (variant === 'secondary') return <ChevronRight color={iconSpecs.color} size={iconSpecs.size} />;
};
const getLeftIcon = (variant: ButtonVariant, iconSpecs: IconProps) => {
  if (variant === 'main') return <ArrowLeft color={iconSpecs.color} size={iconSpecs.size} />;
  else if (variant === 'secondary') return <ChevronLeft color={iconSpecs.color} size={iconSpecs.size} />;
  else if (variant === 'secondary-top-menu') return <TopMenuArrow color={iconSpecs.color} size={iconSpecs.size} />;
};
export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    color = 'normal',
    variant = 'main',
    rightArrow = false,
    leftArrow = false,
    fluid = false,
    onClick,
    title,
    type = 'button',
    className,
    form,
    children,
  } = props;
  const secondary = variant === 'secondary' || variant === 'secondary-top-menu';
  const link = variant === 'link';
  const inverted = color === 'inverted';
  const breakpoint = useBreakpoint();
  const iconSize = breakpoint < breakpoints.md ? { width: 25, height: 25 } : { width: 40, height: 40 };
  const largeIconSize = breakpoint < breakpoints.xl ? { width: 37, height: 37 } : { width: 86, height: 86 };
  const mainVariant = variant === 'main';
  const mainLargeVariant = variant === 'main-large';
  const leftIcon = leftArrow && getLeftIcon(variant, { size: iconSize, color: 'black' });
  const rightIcon = rightArrow && getRightIcon(variant, { size: mainLargeVariant ? largeIconSize : iconSize, color: 'black' });

  const buttonClasses = classnames(
    styles.button,
    {
      [styles['button--main']]: mainVariant,
      [styles['button--main-large']]: mainLargeVariant,
      [styles['button--secondary']]: secondary,
      [styles['button--secondary-top-menu']]: variant === 'secondary-top-menu',
      [styles['button--secondary--right']]: secondary && rightArrow,
      [styles['button--link']]: link,
      [styles['button--fluid']]: fluid,
      [styles['button--inverted']]: inverted,
    },
    className
  );

  return (
    <button data-analyticsid="button" form={form} type={type} className={buttonClasses} onClick={onClick}>
      {leftIcon}
      <span
        className={classnames({
          [styles['button__label--main']]: mainVariant,
          [styles['button__label--secondary']]: secondary,
          [styles['button__label--link']]: link,
        })}
        data-text={children?.toString()}
      >
        {title && (
          <Title className={styles['button__title']} htmlMarkup={'div'} visualHeading={'h3-entry'} color={'black'}>
            {title}
          </Title>
        )}
        {children}
      </span>
      {rightIcon}
    </button>
  );
};
