import React from 'react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RouteObject, RouterProvider, createHashRouter } from 'react-router-dom';

import HvemErDu from './containers/hvem-er-du';
import { Inngangside } from './containers/inngangsside';
import KanIkkeHjelpe from './containers/kan-ikke-hjelpe';
import OmVerktoyet from './containers/om-verktoyet';
import { Quiz } from './containers/quiz';
import { QuizInngangside } from './containers/quiz-inngangsside';
import { SvarSide } from './containers/svarside';
import { Tema } from './containers/tema';
import { TemaValg } from './containers/temavalg';
import { setupStore } from './redux/store';

const anchor: Element | null = document.getElementById('selvtest-app');

export const routes: RouteObject[] = [
  { path: '/', element: <Inngangside /> },
  { path: '/om-verktoyet', element: <OmVerktoyet /> },
  { path: '/hva-beskriver-deg-best', element: <HvemErDu /> },
  { path: '/hvor-gammel-er-du', element: <HvemErDu /> },
  { path: '/for-ung', element: <KanIkkeHjelpe /> },
  { path: '/for-gammel', element: <KanIkkeHjelpe /> },
  { path: '/quiz/*', element: <Quiz /> },
  { path: '/quizinngang', element: <QuizInngangside /> },
  { path: '/quiztemavalg', element: <TemaValg /> },
  { path: '/quizbeklager', element: <KanIkkeHjelpe /> },
  { path: '/quizsvar/*', element: <SvarSide /> },
  { path: '/temavalg', element: <TemaValg /> },
  { path: '/temavalg/*', element: <Tema /> },
  { path: '/temabeskrivelse/*', element: <SvarSide /> },
];

const router = createHashRouter(routes);

const Root: React.FC = () => <RouterProvider router={router} />;

if (anchor) {
  render(
    <Provider store={setupStore()}>
      <Root />
    </Provider>,
    anchor
  );
}
