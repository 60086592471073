import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

export type ContentDirection = 'row' | 'column';

export interface ContentWrapperProps {
  direction?: ContentDirection;
  classNames?: string;
  children?: React.ReactNode;
  gridClass?: boolean;
}

export const ContentWrapper: React.FunctionComponent<ContentWrapperProps> = props => {
  const { gridClass = true, direction, children, classNames } = props;
  const contentWrapperClasses = classnames(
    styles['content-wrapper'],
    {
      [styles['content-wrapper__grid']]: gridClass,
      [styles['content-wrapper--column']]: direction === 'column',
    },
    classNames
  );

  return <div className={contentWrapperClasses}>{children}</div>;
};
export default ContentWrapper;
