import React from 'react';

import classnames from 'classnames';

import { useBreakpoint } from '@helsenorge/designsystem-react/hooks/useBreakpoint';
import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { useAppSelector } from '../../redux/hooks';
import { selectIntroChoiceUrl } from '../../redux/selectors';
import { IntroChoiceUrlEnum } from '../../redux/selvtestSlice';
import { IconProps } from '../../utils/Icons/Icon';

import styles from './styles.module.scss';

interface TemaValgButtonProps {
  /** Icon to display  */
  icon?: React.ReactNode;
  /** Adds custom classes to the element. */
  className?: string;
  /** Function that triggers on click. */
  onClick?: () => void;
}

export const TemaValgButton: React.FC<TemaValgButtonProps> = ({ icon, className, onClick, children }) => {
  const breakpoint = useBreakpoint();
  const quiz = useAppSelector(selectIntroChoiceUrl) === IntroChoiceUrlEnum.quizinngang;
  const mobile = breakpoint < breakpoints.md;
  const temavalgClasses = classnames(styles['temavalg-button'], className, { [styles['temavalg-button--quiz']]: quiz });

  const renderIcon = () => {
    let iconSize = { width: 180, height: 180 };
    if (mobile) {
      iconSize = { width: 83, height: 83 };
    }

    return icon ? React.cloneElement(icon as React.ReactElement<IconProps>, { size: iconSize }) : null;
  };

  return (
    <button className={temavalgClasses} onClick={onClick}>
      <div className={styles['temavalg-button__icon']}>{renderIcon()}</div>
      <h2 className={styles['temavalg-button__text']}>{children}</h2>
    </button>
  );
};
