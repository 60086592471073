import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

interface TitleProps {
  className?: string;
  color?: 'white' | 'black';
  htmlMarkup?: 'h1' | 'h2' | 'h3' | 'div';
  visualHeading?: 'h1' | 'h1-entry' | 'h1-alternate' | 'h2' | 'h2-alternate' | 'h3' | 'h3-entry' | 'h4';
  /** Gives a unique id to the title */
  id?: string;
  subHeading?: string | null;
  infoSymbol?: boolean;
}

export const Title: React.FC<TitleProps> = ({
  className,
  color = 'white',
  htmlMarkup = 'h1',
  visualHeading = htmlMarkup,
  children,
  id,
  subHeading,
  infoSymbol,
}) => {
  const titleClass = classnames(
    styles.title,
    {
      [styles['title--black']]: color === 'black',
      [styles[`title--h1-entry`]]: visualHeading === 'h1-entry',
      [styles[`title--h1-alternate`]]: visualHeading === 'h1-alternate',
      [styles[`title--h2`]]: visualHeading === 'h2',
      [styles[`title--h2-alternate`]]: visualHeading === 'h2-alternate',
      [styles[`title--h3`]]: visualHeading === 'h3',
      [styles[`title--h3-entry`]]: visualHeading === 'h3-entry',
      [styles[`title--h4`]]: visualHeading === 'h4',
      [styles['title--inline']]: infoSymbol,
    },
    className
  );
  const HtmlMarkupTag = htmlMarkup;
  return (
    <>
      <div className={styles['title-wrapper']}>
        <HtmlMarkupTag id={id} className={titleClass}>
          {children}
        </HtmlMarkupTag>
        {infoSymbol && <span className={styles['info']}>{'ⓘ'}</span>}
      </div>
      {subHeading && (
        <div className={styles.subtitle} role="doc-subtitle">
          {subHeading}
        </div>
      )}
    </>
  );
};
