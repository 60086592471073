import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationType } from '../../types/enum';

import { Button } from '../../components/button';
import { Title } from '../../components/title';
import { useAppSelector } from '../../redux/hooks';
import { selectIntroChoiceUrl, selectPage } from '../../redux/selectors';
import { harQuizInngang } from '../../utils/helper';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const ExitSection: React.FunctionComponent = () => {
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const introChoiceUrl = useAppSelector(selectIntroChoiceUrl);
  const quizFarge = harQuizInngang(introChoiceUrl);
  const exit = localState?.exit;

  const navigateExit = localState?.navigationButtons && localState.navigationButtons.filter(e => e.type === NavigationType.exit);
  const navigate = useNavigate();

  return (
    <div className={styles['exit-wrapper']}>
      {exit &&
        exit.map((e, index) => {
          const nExit = navigateExit && Array.isArray(navigateExit) ? navigateExit[index] : navigateExit;
          return (
            <div key={index} className={styles['exit-container']}>
              {e.exitTitle && (
                <Title color={'white'} htmlMarkup={'h2'} visualHeading={'h3'}>
                  {e.exitTitle}
                </Title>
              )}
              {e.exitIngress && <div className={styles['exit-container__text']}>{e.exitIngress}</div>}
              {nExit && (
                <Button color={quizFarge ? 'inverted' : 'normal'} onClick={() => safeNavigate(navigate, nExit.navigateTo)} rightArrow>
                  {nExit.text}
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ExitSection;
