import { IconProps } from './Icon';

const ArrowRight = ({ size = { width: 40, height: 0 }, color }: IconProps) => (
  <span style={{ width: size.width, height: size.height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <svg role={'presentation'} width={size.width} height={size.width} viewBox="0 0 40 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_61_125)">
        <path d="M20.9386 6.66675L18.5886 9.01675L27.8886 18.3334H7.60522V21.6667H27.8886L18.5886 30.9834L20.9386 33.3334L34.2719 20.0001L20.9386 6.66675Z" />
      </g>
      <defs>
        <clipPath id="clip0_61_125">
          <rect width="40" height="40" fill="white" transform="translate(0.938599)" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default ArrowRight;
