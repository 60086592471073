import React from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationType } from '../../types/enum';

import { breakpoints } from '@helsenorge/designsystem-react/theme/grid';

import { useBreakpoint } from '@helsenorge/designsystem-react';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';

import { Button } from '../../components/button';
import { Title } from '../../components/title';
import { Wrapper } from '../../components/wrapper';
import WrapperInner from '../../components/wrapper/inner';
import { useAppSelector } from '../../redux/hooks';
import { selectAnsweredIntro, selectPage } from '../../redux/selectors';
import { Choice, IntroChoiceUrlEnum, setIntroChoiceUrl, setLane } from '../../redux/selvtestSlice';
import { safeNavigate } from '../../utils/navigate';

import styles from './styles.module.scss';

export const Inngangside: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const localState = useAppSelector(state => selectPage(state, location.pathname));
  const answeredIntro = useAppSelector(selectAnsweredIntro);
  const breakpoint = useBreakpoint();
  const mobile = breakpoint < breakpoints.md;
  const title = localState?.title;
  const ingress1 = localState?.ingress1;
  const choices = localState?.choices;
  const navigateOmVerktoyet = choices && choices[2];
  const navigateForward = localState?.navigationButtons && localState.navigationButtons.find(e => e.type === NavigationType.forward);
  const navigate = useNavigate();

  if (typeof localState === 'undefined') {
    return null;
  }
  React.useEffect(() => {
    dispatch(setLane(undefined));
  }, []);
  const introButtonOnClick = (choice: Choice) => {
    dispatch(
      setIntroChoiceUrl(choice.navigateTo === IntroChoiceUrlEnum.temavalg ? IntroChoiceUrlEnum.temavalg : IntroChoiceUrlEnum.quizinngang)
    );
    if (!answeredIntro) {
      safeNavigate(navigate, 'hva-beskriver-deg-best');
    } else {
      safeNavigate(navigate, choice.navigateTo);
    }
  };

  return (
    <Wrapper
      wrapperClassNames={styles['inngang-wrapper']}
      topMenu={'hide'}
      onClickForward={() => safeNavigate(navigate, navigateForward?.navigateTo)}
    >
      <WrapperInner variant={'extra-wide'}>
        {title && <Title visualHeading={'h1-entry'}>{title}</Title>}
        <div className={styles['content-wrapper']}>
          {ingress1 && (
            <p className={styles.text}>
              <SafeHTML html={ingress1} />
            </p>
          )}
          <div className={styles['buttons-wrapper']}>
            <div className={styles['inngang-buttons-wrapper']}>
              {choices &&
                choices.slice(0, 2).map((choice, index) => {
                  const firstElement = index === 0;
                  return (
                    <Button
                      key={index}
                      color={firstElement ? 'normal' : 'inverted'}
                      onClick={() => introButtonOnClick(choice)}
                      variant={'main-large'}
                      rightArrow={!mobile}
                      title={choice.title}
                    >
                      <span className={styles[`inngang-buttons-text-${firstElement ? '1' : '2'}`]}>{choice.ingress}</span>
                    </Button>
                  );
                })}
            </div>
          </div>
        </div>
        {navigateOmVerktoyet?.title && (
          <div className={styles['verktoy-wrapper']}>
            <div className={styles['verktoy-section']}>{navigateOmVerktoyet.title}</div>
            <div className={styles['verktoy-section']}>
              {navigateOmVerktoyet.ingress}
              <button className={styles['verktoy-button']} onClick={() => safeNavigate(navigate, navigateOmVerktoyet.navigateTo)}>
                {navigateOmVerktoyet.text}
              </button>
            </div>
          </div>
        )}
      </WrapperInner>
    </Wrapper>
  );
};
